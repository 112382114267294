/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { dispatch, store } from '../store';
import { loggedInUser, logOutUser } from 'store/slices/user';
import { openSnackbar } from 'store/slices/snackbar';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.message) || 'Wrong Services')
);

const securedAxios = () =>
    axios.create({
        //  baseURL: 'http://localhost:5000/',
        baseURL: process.env.REACT_APP_LIMS_API,
        withCredentials: true
    });

const myBasicAxios = () =>
    axios.create({
        //  baseURL: 'http://localhost:4000/',
        baseURL: process.env.REACT_APP_LIMS_IAM,
        withCredentials: true
    });

const myPrivateAxios = () => {
    axios.create({
        baseURL: 'http://localhost:5000',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
};
export const securedAxiosAPI = securedAxios();
export const basicAxios = myBasicAxios();
export const privateAxios = myPrivateAxios();

// Request Handler

securedAxiosAPI.interceptors.request.use(
    (config) => {
        const { getState } = store;
        const { userToken } = getState().user.loggedInUser;
        // console.log(config.headers);
        if (!config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${userToken}`;

            //  config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Handler

securedAxiosAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 403 && !prevRequest?.sent) {
            try {
                prevRequest.sent = true;
                const { getState } = store;
                const { loggedInPortal } = getState().user;

                const resp = await basicAxios.get('master_auth/refresh', {
                    withCredentials: true
                });
                // if (loggedInPortal === 'LAB') {
                //     resp = await basicAxios.get('auth/refresh', {
                //         withCredentials: true
                //     });
                // } else if (loggedInPortal === 'CUSTOMER') {
                //     // customer-portal-user-refresh
                //     resp = await basicAxios.get('customer-user-auth/refresh', {
                //         withCredentials: true
                //     });
                // }

                const {
                    user: { id, email, name, role, role_name: roleName, customer_id: customerId, loginPortal, customer_name: companyName },
                    accessToken,
                    userRoles
                } = resp?.data;

                dispatch({
                    type: loggedInUser.type,
                    payload: { userID: id, email, name, userToken: accessToken, roleId: role, roleName, userRoles, customerId, companyName }
                });
                prevRequest.headers.Authorization = `Bearer ${accessToken}`;
                return basicAxios(prevRequest);
            } catch (error) {
                dispatch({ type: logOutUser.type });
                dispatch({
                    type: openSnackbar.type,
                    payload: {
                        open: true,
                        message: 'Session Expired',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false,
                        actionButton: false
                    }
                });
                return window.location.reload();
            }
        }

        let myError;
        if (typeof error?.response?.data === 'object') {
            myError = error?.response?.data.message;
        } else {
            myError = error?.response?.data;
        }
        console.log(myError, typeof error?.response?.data === 'object', error);
        // throw new Error(error);
        throw new Error(myError);
        // return error;
    }
);

export default axiosServices;
