import * as React from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { Controller, useFormState } from 'react-hook-form';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function MaterialUIPickers({ name, views, chnageWatcher = () => {}, inputFormat, sx, className, label }) {
    const formState = useFormState();

    return (
        <Controller
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <DesktopDatePicker
                    label={label}
                    inputFormat={inputFormat}
                    views={views ? [...views] : ['year', 'month', 'day']}
                    sx={{}}
                    onChange={(info) => {
                        chnageWatcher(false, info, name);
                        onChange(info);
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    helperText={(formState.errors && formState.errors[name]?.message) || ' '}
                    renderInput={(params) => <TextField sx={{ ...sx, width: '100%' }} {...params} className={className} />}
                />
            )}
        />
    );
}

export function MaterialDatePicker({ name, views, chnageWatcher = () => {}, inputFormat, sx, className, label }) {
    const formState = useFormState();

    return (
        <Controller
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                    label={label}
                    views={views}
                    sx={{}}
                    onChange={(info) => {
                        chnageWatcher(false, info, name);
                        onChange(info);
                    }}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    helperText={(formState.errors && formState.errors[name]?.message) || ' '}
                    renderInput={(params) => <TextField sx={{ ...sx, width: '100%' }} {...params} className={className} />}
                />
            )}
        />
    );
}
