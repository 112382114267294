import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';

const securedRoute = {
    addMoreTests: '/testRequestDetail/add-more-tests',
    base: 'testRequestDetail',
    updateIteration: '/testRequestDetail/updateIteration',
    updateTestReqDetail: '/testRequestDetail/updateTestReqDetail',
    requestedTestByStatus: '/testRequestDetail/requestedTestByStatus',
    // customer portal
    requestDetailsByReqId: 'customerPortal/customerfindByTestRequestIdAllType'
};
export const getTestRequestDetailsByTestRequestId = async (id) => {
    const { getState } = store;
    // alert('ok');
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    } else if (loggedInPortal === 'CUSTOMER') {
        // customer portal
        res = await securedAxiosAPI.post(securedRoute?.requestDetailsByReqId, { test_request_id: id, customer_id: customerId });
    }

    return res.data;
};
export const addMoreTestReq = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addMoreTests, data);
    return res.data;
};
export const updateIterationTestReq = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateIteration, data);
    return res.data;
};

export const updateTestReqDetail = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateTestReqDetail, data);
    return res.data;
};

export const getRequestedTestByStatus = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.requestedTestByStatus, data);
    return res.data;
};
