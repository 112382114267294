import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

export default function SimpleCollapse({ control, CollapseComponent, infoData }) {
    return (
        <Box>
            <Collapse in={control}>
                <CollapseComponent infoData={infoData} />
            </Collapse>
        </Box>
    );
}
