import * as React from 'react';
// material-ui
import { Button, Grid, Typography, Badge, Box, TextField, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
// project imports
import InTransitTestList from './in_transit_list';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import PopupDialogs from 'ui-component/MuiReusableComponents/MuiPopupDialog/new-popup';
import FormHead from 'ui-component/MuiReusableComponents/FormHeadText/formHeadText';
// assets
import AddIcon from '@mui/icons-material/AddTwoTone';
import FilterListIcon from '@mui/icons-material/FilterListTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import {
    startAllTestRequest,
    testRequestsSelector,
    isLoadingSelector,
    isLoading2Selector,
    rowsPerPageSelector,
    startTestRequestDataByFilter,
    setRowsPerPage,
    setPage,
    pageSelector,
    testRequestDataSelector,
    testRequestDetailssSelector
} from 'store/slices/testRequest/testRequests';
import AnimatedLoading from 'ui-component/MuiReusableComponents/AnimationLoading/animationLoading';
import { FormProvider, useForm } from 'react-hook-form';
import CollapseFilterCoverBox, { SearchBox } from 'ui-component/CollapseFilterCoverBox/collapseFilterCoverBox';
import MyTextField from 'ui-component/MuiReusableComponents/MuiFormComponents/TextField.Component';
import MySelect from 'ui-component/MuiReusableComponents/MuiFormComponents/Select.component';
import { textFieldValidationsRules } from 'assets/function utils/functionUtils';
import SimpleCollapse from 'ui-component/MuiCollapse/collapseMUI';
import TestDetailsComp from 'views/common-component/test-req-details';
import { lookUpDetailByIdSelector, startLookUpDetailsByLookupId1 } from 'store/slices/lookMaster/lookupDetailsSlice.selector';
import { getProductsMappedWithCode } from 'utils/apis/productProductCode';
// ==============================|| USER LIST STYLE 1 ||============================== //

// // Needed Component
const FilterAndSearchBox = ({ infoData }) => {
    const dispatch = useDispatch();
    const { setCollapseFilterControl, setFilterStatus, setFilterData } = infoData;
    const [autocomplt, setAutoComplt] = React.useState({});
    const [products, setProducts] = React.useState([]);

    async function fetchProducts() {
        const response = await getProductsMappedWithCode();
        setProducts(response?.map((data) => ({ ...data, p_name: `${data?.p_code} - ${data?.p_name}` })));
    }
    // /FORM
    React.useEffect(() => {
        dispatch(startLookUpDetailsByLookupId1(2));
        fetchProducts();
    }, []);
    const { lookupDetailForId1: sampleTypeDB } = useSelector(lookUpDetailByIdSelector);

    const form = useForm({
        defaultValues: {
            sample_id: '',
            batch: '',
            status: 0,
            sample_type: -1
        }
    });
    const sampleType = sampleTypeDB.map((item) => ({
        name: item.name,
        id: item.id
    }));

    const onSubmit = (data) => {
        setFilterStatus(true);
        dispatch(setRowsPerPage(10));
        dispatch(setPage(0));
        dispatch(startTestRequestDataByFilter({ ...data, p_map_id: autocomplt?.id, page: 0, rowsPerPage: 10 }));
        setFilterData({ ...data, p_map_id: autocomplt?.id });
    };

    const onClickReset = () => {
        setFilterStatus(false);
        form.reset({
            sample_id: '',
            p_name: '',
            batch: '',
            request_status: 1,
            sample_type: -1
        });
        setAutoComplt({});
        dispatch(setRowsPerPage(10));
        dispatch(setPage(0));
        dispatch(startAllTestRequest({ status: 0 }));
    };
    return (
        <CollapseFilterCoverBox>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={1} columnSpacing={1} className="parent-grid">
                        <Grid item xs={12} sm={4} md={3}>
                            <Box className="box-parent">
                                <FormHead headText="Sample Id " />
                                <MyTextField
                                    size="small"
                                    type="text"
                                    name="sample_id"
                                    placeholder="Search By Sample Id"
                                    fullWidth
                                    rules={{ ...textFieldValidationsRules.textField }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Box className="box-parent">
                                <FormHead headText="Product Name " />
                                <Autocomplete
                                    value={autocomplt}
                                    error
                                    displayEmpty
                                    //   sx={{ justifyContent: 'center', alignContent: 'center', height: '10px !important' }}
                                    getOptionLabel={(option) => (option?.p_name ? option?.p_name : '')}
                                    onChange={(event, newValue) => {
                                        setAutoComplt(newValue);
                                        // form.setValue('p_code', newValue?.p_code);
                                    }}
                                    options={products}
                                    renderInput={(params) => <TextField {...params} size="small" placeholder="Select Product Name" />}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Box className="box-parent">
                                <FormHead headText="Batch No. " />
                                <MyTextField
                                    size="small"
                                    type="text"
                                    name="batch"
                                    placeholder="Search By Batch No."
                                    fullWidth
                                    rules={{ ...textFieldValidationsRules.textField }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2.5}>
                            <Box className="box-parent">
                                <FormHead headText="Sample Type " />
                                <MySelect
                                    fullWidth
                                    name="sample_type"
                                    placeholder="Sample Type *"
                                    sx={{ flexGrow: '1', minWidth: '100%' }}
                                    size="small"
                                    valueField="id"
                                    items={[{ name: 'All Sample Type', id: -1 }, ...sampleType]}
                                    helperReq={false}
                                    defaultItem={{ id: '', name: 'Search by Sample Type' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <SearchBox onClickReset={onClickReset} setCollapseFilterControl={setCollapseFilterControl} />
                </form>
            </FormProvider>
        </CollapseFilterCoverBox>
    );
};
const Intransit = () => {
    const [collapseFilterControl, setCollapseFilterControl] = React.useState(false);
    const [filterStatus, setFilterStatus] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});

    const [open, setOpen] = React.useState(false);

    const testReqData = useSelector(testRequestDataSelector);
    const testRequestDetail = useSelector(testRequestDetailssSelector);

    const dispatch = useDispatch();
    const testData = useSelector(testRequestsSelector);
    const isLoading = useSelector(isLoadingSelector);
    const isLoading2 = useSelector(isLoading2Selector);
    const page = useSelector(pageSelector);
    const rowsPerPage = useSelector(rowsPerPageSelector);
    React.useEffect(() => {
        if (!filterStatus) {
            dispatch(startAllTestRequest({ status: 0 }));
        } else {
            dispatch(startTestRequestDataByFilter({ ...filterData, status: 0 }));
        }
    }, [page, rowsPerPage]);
    const onclickFilter = () => setCollapseFilterControl(!collapseFilterControl);
    return (
        <>
            <MainCard
                title={
                    <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                        <Grid item>
                            <Typography variant="h3">Specimen In-Transit</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                            <Button
                                variant="contained"
                                onClick={onclickFilter}
                                sx={{ marginRight: '15px' }}
                                startIcon={
                                    filterStatus ? (
                                        <Badge size="small" badgeContent={1} color="error" title="filter active">
                                            <FilterListIcon size="small" />
                                        </Badge>
                                    ) : (
                                        <FilterListIcon size="small" />
                                    )
                                }
                            >
                                Filters
                            </Button>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                <SimpleCollapse
                    infoData={{
                        setFilterStatus,
                        filterStatus,
                        setCollapseFilterControl,
                        filterData,
                        setFilterData
                    }}
                    control={collapseFilterControl}
                    CollapseComponent={FilterAndSearchBox}
                />
                {isLoading2 ? (
                    <AnimatedLoading />
                ) : (
                    <InTransitTestList testData={testData} setOpen={setOpen} filterStatus={filterStatus} filterData={filterData} />
                )}
            </MainCard>
            <PopupDialogs title="Sample Detail" needTitleBar={false} size="lg" open={open} setOpen={setOpen}>
                {isLoading ? (
                    <AnimatedLoading />
                ) : (
                    <Paper>
                        <TestDetailsComp testReqData={testReqData} testRequestDetail={testRequestDetail} />
                        <Box textAlign="center" mt="1rem">
                            <Button onClick={() => setOpen(false)} startIcon={<CloseIcon />} color="error" variant="outlined">
                                Close
                            </Button>
                        </Box>
                    </Paper>
                )}
            </PopupDialogs>
        </>
    );
};
export default Intransit;
