import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';

const securedRoute = {
    addCustomerUser: 'customerUser/add',
    clientUsersByCustomerID: 'customerUser',
    getByPaginationCustomerId: 'customerUser/users-by-customer-id-pagination',
    // userData: 'customerUser/{id}'
    updateUserDetail: 'customerUser/update-user-detail',
    resetCustomerUserPasswordByAdmin: 'customerUser/reset-customer-user-passwordByAdmin'
};
export const addCustomerUser = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addCustomerUser, data);
    return res.data;
};

export const customerUsers = async (data) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.clientUsersByCustomerID}/${data}`);
    return res.data[0];
};
export const getCustomerUsersByPaginationAndCustomerId = async (customerId) => {
    const { getState } = store;
    const { userPage: page, userRowsPerPage: rowsPerPage } = getState().customer;
    const res = await securedAxiosAPI.post(securedRoute?.getByPaginationCustomerId, { customer_id: customerId, page, rowsPerPage });
    return res.data;
};
export const updateUserDetail = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateUserDetail, data);
    return res.data[0];
};

export const resetCustomerUserPasswordByAdmin = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.resetCustomerUserPasswordByAdmin, data);
    return res.data;
};
