import * as React from 'react';
// material-ui
import {
    Grid,
    useTheme,
    Select,
    MenuItem,
    ListItemText,
    FormControl,
    OutlinedInput,
    Checkbox,
    Box,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import MyTextField from 'ui-component/MuiReusableComponents/MuiFormComponents/TextField.Component';
import MaterialUIPickers from 'ui-component/MuiReusableComponents/MuiFormComponents/DatePicker/date.picker.component';
// import { MUISelectMulti } from 'ui-component/MuiReusableComponents/MuiFormComponents/Select.component';
import { SumbitHandler } from './index';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import { arrayToObject } from 'assets/function utils/functionUtils';
// project imports
import { FormProvider, useForm, Controller } from 'react-hook-form';
import FormHead from 'ui-component/MuiReusableComponents/FormHeadText/formHeadText';
import { gridSpacing } from 'store/constant';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
const defaultItem = {
    microbiology: { id: '', name: 'Select Microbiology ' },
    allergens: { id: '', name: 'Select Allergens ' },
    nops: { id: '', name: 'Select NOPS' },
    waterActivity: { id: '', name: 'Select water Activity' },
    mycotoxin: { id: '', name: 'Select Mycotoxin ' },
    moistureAnalysis: { id: '', name: 'Select Moisture Analysis ' },
    pH: { id: '', name: 'Select pH ' }
};
const labelAndAllParentCss = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };
export default function Analysis({
    microbiology,
    allergens,
    value,
    handleChange,
    nops,
    setFormStates,
    formStates,
    waterActivity,
    mycotoxin,
    moistureAnalysis,
    pH
}) {
    // const theme = useTheme();
    const dispatch = useDispatch();
    const allergensObj = arrayToObject(allergens, 'id', 'name');
    const microbiologyObj = arrayToObject(microbiology, 'id', 'name');
    const nopsObj = arrayToObject(nops, 'id', 'name');

    const waterActivityObj = arrayToObject(waterActivity, 'id', 'name');
    const mycotoxinObj = arrayToObject(mycotoxin, 'id', 'name');
    const moistureAnalysisObj = arrayToObject(moistureAnalysis, 'id', 'name');
    const phObj = arrayToObject(pH, 'id', 'name');

    const [filledAnalysis, setFilledAnalysis] = React.useState('INITIAL');
    const [allCheckBox, setAllCheckBox] = React.useState({
        microbiology: false,
        allergens: false,
        nops: false,
        waterActivity: false,
        mycotoxin: false,
        moistureAnalysis: false,
        pH: false
    });

    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            microbiology: [],
            allergens: [],
            nops: [],
            waterActivity: [],
            mycotoxin: [],
            moistureAnalysis: [],
            pH: [],
            sent_by: '',
            turn_time: new Date(),
            sent_date: new Date(),
            ...formStates
        }
    });

    const onSubmit = (data) => {
        if (
            data.allergens.length === 0 &&
            data.microbiology.length === 0 &&
            data.nops.length === 0 &&
            data.waterActivity.length === 0 &&
            data.mycotoxin.length === 0 &&
            data.moistureAnalysis.length === 0 &&
            data.pH.length === 0
        ) {
            setFilledAnalysis('NOT_FILLED');
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Please ensure that you have selected the required tests',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false,
                    actionButton: false
                })
            );
        } else if (data?.turn_time < data?.sent_date) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Please ensure that you have Turnaround Time is greater then Sent Date.',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false,
                    actionButton: false
                })
            );
        } else {
            //  alert('ok');
            setFormStates((prev) => {
                const updObj = { ...prev, ...data };
                return updObj;
            });

            handleChange({}, value + 1);
        }
    };
    const analysisFilled = () => {
        setFilledAnalysis('Filled');
    };
    const onChnageCheckBoxHandler = (event) => {
        const {
            target: { name, checked }
        } = event;
        if (name) {
            setAllCheckBox((prev) => ({ ...prev, [name]: checked }));
            if (checked) {
                const selectLists = {
                    microbiology,
                    allergens,
                    nops,
                    waterActivity,
                    mycotoxin,
                    moistureAnalysis,
                    pH
                };
                const allID = selectLists[name].map((data) => data.id);
                form.setValue(name, allID);
            } else {
                form.setValue(name, []);
            }
        }
    };

    //   console.log(formStates, 'ANALYSIS TYPE');
    // alert('ok');
    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={0} columnSpacing={gridSpacing}>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="Microbiology" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                //  label={<Typography variant="caption"> All</Typography>}
                                                label="All Microbiology "
                                                name="microbiology"
                                                checked={allCheckBox?.microbiology}
                                                onClick={onChnageCheckBoxHandler}
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="microbiology"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                disabled={allCheckBox?.microbiology}
                                                id="multiple-checkbox"
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.microbiology.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => microbiologyObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.microbiology?.name && (
                                                    <MenuItem
                                                        disabled
                                                        key={defaultItem.microbiology.name}
                                                        value={defaultItem.microbiology.name}
                                                    >
                                                        {defaultItem.microbiology.name}
                                                    </MenuItem>
                                                )}
                                                {microbiology?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="Allergens" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                checked={allCheckBox?.allergens}
                                                onClick={onChnageCheckBoxHandler}
                                                name="allergens"
                                                label="All Allergens "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="allergens"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                disabled={allCheckBox?.allergens}
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.allergens.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => allergensObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.allergens?.name && (
                                                    <MenuItem disabled key={defaultItem.allergens.name} value={defaultItem.allergens.name}>
                                                        {defaultItem.allergens.name}
                                                    </MenuItem>
                                                )}
                                                {allergens?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="NOPS" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                checked={allCheckBox?.nops}
                                                onClick={onChnageCheckBoxHandler}
                                                size="small"
                                                name="nops"
                                                label="All NOPS "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="nops"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                multiple
                                                disabled={allCheckBox?.nops}
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.nops.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => nopsObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.nops?.name && (
                                                    <MenuItem disabled key={defaultItem.nops.name} value={defaultItem.nops.name}>
                                                        {defaultItem.nops.name}
                                                    </MenuItem>
                                                )}
                                                {nops?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="Water Activity" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                checked={allCheckBox?.waterActivity}
                                                onClick={onChnageCheckBoxHandler}
                                                name="waterActivity"
                                                label="All Water Activity "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="waterActivity"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                disabled={allCheckBox?.waterActivity}
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.waterActivity.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => waterActivityObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.waterActivity?.name && (
                                                    <MenuItem
                                                        disabled
                                                        key={defaultItem.waterActivity.name}
                                                        value={defaultItem.waterActivity.name}
                                                    >
                                                        {defaultItem.waterActivity.name}
                                                    </MenuItem>
                                                )}
                                                {waterActivity?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="Mycotoxin" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                checked={allCheckBox?.mycotoxin}
                                                onClick={onChnageCheckBoxHandler}
                                                name="mycotoxin"
                                                label="All Mycotoxin "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="mycotoxin"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                disabled={allCheckBox?.mycotoxin}
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.mycotoxin.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => mycotoxinObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.mycotoxin?.name && (
                                                    <MenuItem disabled key={defaultItem.mycotoxin.name} value={defaultItem.mycotoxin.name}>
                                                        {defaultItem.mycotoxin.name}
                                                    </MenuItem>
                                                )}
                                                {mycotoxin?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="Moisture Analysis" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                checked={allCheckBox?.moistureAnalysis}
                                                onClick={onChnageCheckBoxHandler}
                                                name="moistureAnalysis"
                                                label="All Moisture Analysis "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="moistureAnalysis"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                disabled={allCheckBox?.moistureAnalysis}
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.moistureAnalysis.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => moistureAnalysisObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.moistureAnalysis?.name && (
                                                    <MenuItem
                                                        disabled
                                                        key={defaultItem.moistureAnalysis.name}
                                                        value={defaultItem.moistureAnalysis.name}
                                                    >
                                                        {defaultItem.moistureAnalysis.name}
                                                    </MenuItem>
                                                )}
                                                {moistureAnalysis?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                <Box sx={labelAndAllParentCss}>
                                    <FormHead headText="pH" />
                                    <Box>
                                        <FormGroup>
                                            <FormControlLabel
                                                size="small"
                                                checked={allCheckBox?.pH}
                                                onClick={onChnageCheckBoxHandler}
                                                name="pH"
                                                label="All pH "
                                                control={<Checkbox size="small" />}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                                <Controller
                                    name="pH"
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControl
                                            sx={{ flexGrow: '1', width: '100%', marginBottom: '1.1rem' }}
                                            error={filledAnalysis === 'NOT_FILLED'}
                                        >
                                            <Select
                                                id="multiple-checkbox"
                                                disabled={allCheckBox?.pH}
                                                multiple
                                                displayEmpty
                                                onChange={(event) => {
                                                    analysisFilled();
                                                    onChange(event);
                                                }}
                                                onBlur={onBlur}
                                                ref={ref}
                                                value={value}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    if (selected.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>{defaultItem.pH.name}</div>;
                                                    }

                                                    const selectNameArray = selected.map((item) => phObj[item]);

                                                    return selectNameArray.join(' /  ');
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {defaultItem.pH?.name && (
                                                    <MenuItem disabled key={defaultItem.pH.name} value={defaultItem.pH.name}>
                                                        {defaultItem.pH.name}
                                                    </MenuItem>
                                                )}
                                                {pH?.map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <Checkbox checked={value.indexOf(item.id) > -1} />
                                                        <ListItemText primary={item.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <FormHead headText="Sent By" required />
                            <MyTextField
                                type="text"
                                // label="Sent By*"
                                name="sent_by"
                                placeholder="Enter Sent By"
                                fullWidth
                                rules={{
                                    required: 'Required',
                                    minLength: {
                                        value: 3,
                                        message: 'Minimun length 3'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormHead headText="Sent Date " required />
                            <MaterialUIPickers fullWidth placeholder="Sent Date *" name="sent_date" inputFormat="dd-MMM-yyyy" />
                        </Grid>
                        <Grid item xs={6}>
                            <FormHead headText="Turnaround Time " required />
                            <MaterialUIPickers fullWidth placeholder="Turnaround Time *" name="turn_time" inputFormat="dd-MMM-yyyy" />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: gridSpacing }}>
                            <SumbitHandler value={value} handleChange={handleChange} />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </>
    );
}
