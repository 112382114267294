import * as React from 'react';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Step, Stepper, StepLabel, useTheme, Grid, CardContent, useMediaQuery, Typography } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import SampleInfo from '../new-test-sample/sampleInfo';
import Analysis from '../new-test-sample/analysisRequired';
import PreviewForm from '../new-test-sample/previewForm';

import {
    isLoadingSelector,
    lookUpDetailByIdSelector,
    startLookUpDetailsByLookupId1,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5
} from 'store/slices/lookMaster/lookupDetailsSlice.selector';
import AnimatedLoading from 'ui-component/MuiReusableComponents/AnimationLoading/animationLoading';
import {
    startAddingNewTest,
    startTestRequestData,
    startTestRequestDetailsByTestRequestId,
    testRequestDataSelector,
    testRequestDetailssSelector,
    isLoadingSelector as testResquestLoadingSelector
} from 'store/slices/testRequest/testRequests';
import { sendDateFormateReadStringToSendStrinf, seprationFunction } from 'assets/function utils/functionUtils';
import { formNavigateSelector, setFormNavigate } from 'store/slices/formNavigate/formNavigateSlice';
import { getProductsMappedWithCode } from 'utils/apis/productProductCode';
import { getAllCustomers } from 'utils/apis/customer';
import { Box } from '@mui/system';
import BackButton from 'ui-component/MuiReusableComponents/BackButton/back-button';
import { GetLookUpDetailsDataByLookupId } from 'utils/apis/lookup';
import { loggedInPortalSelector } from 'store/slices/user';
import { customerDetailsSelector } from 'store/slices/customer/customer';
import { myAnalysisArray } from 'constant';

// Button
export function SumbitHandler({ value, handleChange, formStates, prevDetails }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formNavigate = useSelector(formNavigateSelector);
    const testRequestDetails = useSelector(testRequestDetailssSelector);
    const isLoading = useSelector(testResquestLoadingSelector);
    const [currentTestType, setCurrentTestType] = React.useState({});
    React.useEffect(() => {
        if (formNavigate) {
            navigate('/sample-reception/test-requests');
            dispatch(setFormNavigate(false));
        }
    }, [formNavigate]);

    React.useEffect(() => {
        if (testRequestDetails.length > 0) {
            const filteredArray = testRequestDetails.filter((element) => element?.test_type !== 3);
            filteredArray.forEach((data) => setCurrentTestType((prevData) => ({ ...prevData, [data.test_type]: true })));
        }
    }, [testRequestDetails]);

    return (
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
                {value > 0 && (
                    <AnimateButton>
                        <Button variant="outlined" size="large" onClick={(e) => handleChange(e, value - 1)}>
                            Back
                        </Button>
                    </AnimateButton>
                )}
            </Grid>
            <Grid item>
                {value < 3 && (
                    <AnimateButton>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={isLoading && value === 2}
                            onClick={() => {
                                if (value === 2) {
                                    formStates.st_date = sendDateFormateReadStringToSendStrinf(formStates.st_date);
                                    formStates.mfg_date = sendDateFormateReadStringToSendStrinf(formStates.mfg_date);
                                    formStates.bbf_date = formStates.bbf_date
                                        ? sendDateFormateReadStringToSendStrinf(formStates.bbf_date)
                                        : null;
                                    formStates.turn_time = sendDateFormateReadStringToSendStrinf(formStates.turn_time);
                                    formStates.sent_date = sendDateFormateReadStringToSendStrinf(formStates.sent_date);

                                    const newToAdd = [
                                        {
                                            fillData: [...seprationFunction(formStates?.microbiology, prevDetails?.microbiology)],
                                            testId: 4
                                        },
                                        { fillData: [...seprationFunction(formStates?.allergens, prevDetails?.allergens)], testId: 5 },
                                        { fillData: [...seprationFunction(formStates?.nops, prevDetails?.nops)], testId: 6 },
                                        {
                                            fillData: [...seprationFunction(formStates?.animal_feed, prevDetails?.animal_feed)],
                                            testId: 3
                                        },
                                        //
                                        {
                                            fillData: [...seprationFunction(formStates?.waterActivity, prevDetails?.waterActivity)],
                                            testId: 14
                                        },
                                        {
                                            fillData: [...seprationFunction(formStates?.mycotoxin, prevDetails?.mycotoxin)],
                                            testId: 15
                                        },
                                        {
                                            fillData: [...seprationFunction(formStates?.moistureAnalysis, prevDetails?.moistureAnalysis)],
                                            testId: 16
                                        },
                                        {
                                            fillData: [...seprationFunction(formStates?.pH, prevDetails?.pH)],
                                            testId: 17
                                        }
                                    ];
                                    const toBeDeleted = [
                                        ...seprationFunction(prevDetails?.microbiology, formStates?.microbiology),
                                        ...seprationFunction(prevDetails?.allergens, formStates?.allergens),
                                        ...seprationFunction(prevDetails?.nops, formStates?.nops),
                                        ...seprationFunction(prevDetails?.animal_feed, formStates?.animal_feed),
                                        //
                                        ...seprationFunction(prevDetails?.waterActivity, formStates?.waterActivity),
                                        ...seprationFunction(prevDetails?.mycotoxin, formStates?.mycotoxin),
                                        ...seprationFunction(prevDetails?.moistureAnalysis, formStates?.moistureAnalysis),
                                        ...seprationFunction(prevDetails?.pH, formStates?.pH)
                                    ];
                                    // For creating Certificate number for newly added testtype
                                    const newAddedTypesAll = {};

                                    Object.keys(myAnalysisArray).forEach((item) => {
                                        if (formStates[item].length > 0) {
                                            newAddedTypesAll[myAnalysisArray[item]] = true;
                                        }
                                    });
                                    const newlyTestTypeAdded = {};
                                    Object.keys(newAddedTypesAll).forEach((item) => {
                                        if (!currentTestType[item]) {
                                            newlyTestTypeAdded[item] = true;
                                        }
                                    });

                                    dispatch(
                                        startAddingNewTest({
                                            ...formStates,
                                            p_map_id: formStates.autocompleteProduct.id
                                                ? formStates.autocompleteProduct.id
                                                : formStates.p_map_id,
                                            newToAdd,
                                            toBeDeleted,
                                            newlyTestTypeAdded
                                        })
                                    );
                                }
                            }}
                        >
                            {value === 2 ? 'Submit' : 'Next'}
                        </Button>
                    </AnimateButton>
                )}
            </Grid>
        </Grid>
    );
}
// tabs
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};
SumbitHandler.propTypes = {
    value: PropTypes.number,
    handleChange: PropTypes.func,
    formStates: PropTypes.object
};
export default function EditTestSample() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { test_request_id: testReqId } = useParams();
    const isLoadingLookup = useSelector(isLoadingSelector);
    const testRequestData = useSelector(testRequestDataSelector);
    const testResquestLoading = useSelector(testResquestLoadingSelector);
    const testRequestDetails = useSelector(testRequestDetailssSelector);
    //

    const [products, setProducts] = React.useState([]);
    const [customer, setCustomers] = React.useState([]);
    const loggedInPortal = useSelector(loggedInPortalSelector);
    const customerDetails = useSelector(customerDetailsSelector);
    const fetchCustomers = async () => {
        if (loggedInPortal === 'CUSTOMER') {
            setCustomers([{ id: customerDetails?.id, name: customerDetails?.name }]);
        } else if (loggedInPortal === 'LAB') {
            const response = await getAllCustomers();
            setCustomers(response);
        }
    };
    React.useEffect(() => {
        fetchCustomers();
    }, []);
    // step options
    const matchesXs = useMediaQuery(theme.breakpoints.down('lg'));
    const [value, setValue] = React.useState(0);
    const [prevDetails, setPrevDetailsA] = React.useState({});
    const [waterActivity, setWaterActivity] = React.useState([]);
    const [mycotoxin, setMicotoxin] = React.useState([]);
    const [moistureAnalysis, setMoistureAnalysis] = React.useState([]);
    const [pH, setPH] = React.useState([]);

    async function fetchProducts() {
        const response = await getProductsMappedWithCode();
        setProducts(response);
    }
    const fetchWaterActivitys = async () => {
        const response = await GetLookUpDetailsDataByLookupId(14);
        setWaterActivity(response);
    };
    const fetchMicotoxin = async () => {
        const response = await GetLookUpDetailsDataByLookupId(15);
        setMicotoxin(response);
    };
    const fetchMoistureAnalysis = async () => {
        const response = await GetLookUpDetailsDataByLookupId(16);
        setMoistureAnalysis(response);
    };
    const fetchPH = async () => {
        const response = await GetLookUpDetailsDataByLookupId(17);
        setPH(response);
    };
    React.useEffect(() => {
        // dispatch(startAllCustomer());
        dispatch(startLookUpDetailsByLookupId1(2));
        dispatch(startLookUpDetailsByLookupId2(3));
        dispatch(startLookUpDetailsByLookupId3(4));
        dispatch(startLookUpDetailsByLookupId4(5));
        dispatch(startLookUpDetailsByLookupId5(6));
        dispatch(startTestRequestData(testReqId));
        dispatch(startTestRequestDetailsByTestRequestId(testReqId));
        fetchProducts();
        fetchPH();
        fetchMoistureAnalysis();
        fetchMicotoxin();
        fetchWaterActivitys();
    }, []);
    const {
        lookupDetailForId1: sampleTypeDB,
        lookupDetailForId2: animalFeedDB,
        lookupDetailForId3: microbiologyDB,
        lookupDetailForId4: allergensDB,
        lookupDetailForId5: nopsDB
    } = useSelector(lookUpDetailByIdSelector);

    const [formStates, setFormStates] = React.useState({
        id: 0,
        p_name: '',
        p_code: '',
        batch: '',
        mfg_date: new Date(),
        bbf_date: new Date(),
        sample_id: '',
        animal_feed: [],
        sample_type: '',
        customer_id: '',
        microbiology: [],
        allergens: [],
        nops: [],
        turn_time: new Date(),
        sent_date: new Date(),
        customer_ref: '',
        pallet_id: '',
        comment: ''
    });
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (testRequestData.id && testRequestDetails.length > 0) {
            const detailsTable = {
                animal_feed: [],
                microbiology: [],
                allergens: [],
                nops: [],
                waterActivity: [],
                mycotoxin: [],
                moistureAnalysis: [],
                pH: []
            };
            setPrevDetailsA(detailsTable);
            testRequestDetails.forEach((data) => {
                switch (data?.test_type) {
                    case 3:
                        detailsTable?.animal_feed.push(data?.requested_test);
                        break;
                    case 4:
                        detailsTable?.microbiology.push(data?.requested_test);
                        break;
                    case 5:
                        detailsTable?.allergens.push(data?.requested_test);
                        break;
                    case 6:
                        detailsTable?.nops.push(data?.requested_test);
                        break;
                    case 14:
                        detailsTable?.waterActivity.push(data.requested_test);
                        break;
                    case 15:
                        detailsTable?.mycotoxin.push(data.requested_test);
                        break;
                    case 16:
                        detailsTable?.moistureAnalysis.push(data.requested_test);
                        break;
                    case 17:
                        detailsTable?.pH.push(data.requested_test);
                        break;
                    default:
                }
            });
            setFormStates({
                id: testRequestData?.id,
                p_name: testRequestData?.p_name,
                p_code: testRequestData?.p_code,
                batch: testRequestData?.batch,
                mfg_date: new Date(testRequestData?.mfg_date),
                bbf_date: testRequestData?.bbf_date ? new Date(testRequestData?.bbf_date) : null,
                sample_id: testRequestData?.sample_id,
                animal_feed: detailsTable?.animal_feed,
                sample_type: testRequestData?.sample_type,
                customer_id: testRequestData?.customer_id,
                microbiology: detailsTable?.microbiology,
                allergens: detailsTable?.allergens,
                nops: detailsTable?.nops,
                waterActivity: detailsTable?.waterActivity,
                p_map_id: testRequestData?.p_map_id,
                mycotoxin: detailsTable?.mycotoxin,
                moistureAnalysis: detailsTable?.moistureAnalysis,
                pH: detailsTable?.pH,
                customer_ref: testRequestData?.customer_ref,
                pallet_id: testRequestData?.pallet_id,
                comment: testRequestData?.comment,
                turn_time: new Date(testRequestData?.turn_time),
                sent_date: new Date(testRequestData?.sent_date),
                st_date: new Date(testRequestData?.st_date),
                sent_by: testRequestData.sent_by,
                status: testRequestData?.status,
                result_status: testRequestData?.result_status,
                autocompleteProduct: { p_name: testRequestData?.p_name, p_code: testRequestData?.p_code }
            });
        }
    }, [testRequestData, testRequestDetails]);

    const steps = ['Sample Information', 'Analysis', 'Preview & Submit'];

    return testRequestData?.status > 0.9 ? (
        <Navigate to="/sample-reception/test-requests" replace />
    ) : (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <MainCard
                        title={
                            <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                                <Grid item>
                                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                        {/* <BackButton /> */}
                                        <Typography variant="h3">Edit Test Request</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle1">
                                        Sample Id :{' '}
                                        <span style={{ backgroundColor: theme.palette.warning.light, padding: '5px' }}>
                                            {testRequestData?.sample_id}
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        content={false}
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} lg={2}>
                                <CardContent>
                                    <Stepper
                                        orientation={!matchesXs ? 'vertical' : 'horizontal'}
                                        activeStep={value}
                                        sx={{ pt: 3, pb: 5, textAlign: 'center' }}
                                    >
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <CardContent
                                    sx={{
                                        borderLeft: '1px solid',
                                        borderColor:
                                            theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                        height: '100%'
                                    }}
                                >
                                    <TabPanel value={value} index={0}>
                                        {isLoadingLookup || testResquestLoading || formStates.id === 0 ? (
                                            <AnimatedLoading />
                                        ) : (
                                            formStates.id !== 0 &&
                                            customer.length > 0 && (
                                                <SampleInfo
                                                    sampleType={sampleTypeDB}
                                                    animalFeed={animalFeedDB}
                                                    value={value}
                                                    products={products}
                                                    handleChange={handleChange}
                                                    formStates={formStates}
                                                    setFormStates={setFormStates}
                                                    customer={customer}
                                                />
                                            )
                                        )}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {isLoadingLookup || testResquestLoading || formStates?.id === 0 ? (
                                            <AnimatedLoading />
                                        ) : (
                                            formStates.id !== 0 && (
                                                <Analysis
                                                    value={value}
                                                    handleChange={handleChange}
                                                    profileDetails={formStates}
                                                    microbiology={microbiologyDB}
                                                    nops={nopsDB}
                                                    allergens={allergensDB}
                                                    formStates={formStates}
                                                    setFormStates={setFormStates}
                                                    waterActivity={waterActivity}
                                                    mycotoxin={mycotoxin}
                                                    moistureAnalysis={moistureAnalysis}
                                                    pH={pH}
                                                />
                                            )
                                        )}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <PreviewForm
                                            value={value}
                                            customers={customer}
                                            setValue={setValue}
                                            handleChange={handleChange}
                                            profileDetails={formStates}
                                            microbiology={microbiologyDB}
                                            allergens={allergensDB}
                                            formStates={formStates}
                                            sampleType={sampleTypeDB}
                                            animalFeed={animalFeedDB}
                                            nops={nopsDB}
                                            prevDetails={prevDetails}
                                            waterActivity={waterActivity}
                                            mycotoxin={mycotoxin}
                                            moistureAnalysis={moistureAnalysis}
                                            pH={pH}
                                        />
                                    </TabPanel>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
}
