// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    formNavigate: false
};

const slice = createSlice({
    name: 'formNavigate',
    initialState,
    reducers: {
        setFormNavigate(state, action) {
            state.formNavigate = action.payload;
        }
    }
});
// Actions Exports
export const { setFormNavigate } = slice.actions;
// Reducer
export default slice.reducer;

// selectors

export const formNavigateSelector = (state) => state.formNavigate?.formNavigate;
