import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaperBox = styled(Paper)({
    borderRadius: '10px',
    marginBottom: '15px',
    backgroundColor: '#dff9fb87',

    '.box': {
        textAlign: 'right',
        width: '100%',
        paddingTop: '10px',
        backgroundColor: '#ffeaa7b3',
        '.text': {
            width: '100%',

            textAlign: 'left',
            fontWeight: 'bold',
            paddingLeft: '15px'
        }
    },
    '.parent-grid': {
        padding: '10px 20px 0px 20px',
        '.box-parent': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    '.parent-search-box': {
        width: '100%',
        textAlign: 'center',
        paddingBottom: '10px',
        '.button-space': {
            marginRight: '15px'
        }
    }
});
const MyCollapsePaper = ({ children, ...otherProp }) => <StyledPaperBox {...otherProp}>{children} </StyledPaperBox>;
export default MyCollapsePaper;
