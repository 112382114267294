// third-party
import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import labReducer from './slices/lab/lab';
import userReducer from './slices/user';
import customerReducer from './slices/customer/customer';
import testRequests from './slices/testRequest/testRequests';
import testTemplateReducer from './slices/testTemplate/testTemplate.slice';
import menuReducer from './slices/menu';
import formNavigateReducer from './slices/formNavigate/formNavigateSlice';
import generatedSampleIdReducer from './slices/generatedSampleId/generatedSampleId';
// Config
import lookupDetailReducer from './slices/lookMaster/lookupDetailsSlice.selector';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    user: userReducer,
    menu: menuReducer,
    customer: customerReducer,
    lookupDetails: lookupDetailReducer,
    generatedSampleId: generatedSampleIdReducer,
    testRequests,
    testTemplate: testTemplateReducer,
    lab: labReducer,
    //  test: myCall(),
    // if error dont navigate
    formNavigate: formNavigateReducer
});

export default reducer;
