// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    isLoading: false,
    isLoadingAdd: false,
    message: '',
    generatedSampleId: []
};

const slice = createSlice({
    name: 'generatedSampleId',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startAddingNewGenSampleId(state, action) {
            state.isLoadingAdd = true;
        },

        startGeneratedSampleId(state, action) {
            state.isLoading = true;
        },
        setGeneratedSampleId(state, action) {
            state.generatedSampleId = action.payload;
        },
        startDeleteGeneratedSampleId(state, action) {
            state.isLoading = true;
        },
        // Dynamic Filter
        startDynamicFilter(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
            state.isLoadingAdd = false;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    completedAction,
    startDeleteGeneratedSampleId,
    startGeneratedSampleId,
    setGeneratedSampleId,
    startAddingNewGenSampleId,
    startDynamicFilter
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors

const generatedSampleIdStates = (state) => state.generatedSampleId;
/// Data Loading Selector
export const isLoadingSelector = createSelector([generatedSampleIdStates], (dataState) => dataState.isLoading);
/// Data Add Loading Selector
export const isLoadingAddSelector = createSelector([generatedSampleIdStates], (dataState) => dataState.isLoadingAdd);
// generatedSampleId List
export const generatedSampleIdSelector = createSelector([generatedSampleIdStates], (dataState) => dataState.generatedSampleId);

// Page Selector
export const pageSelector = createSelector([generatedSampleIdStates], (dataState) => dataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([generatedSampleIdStates], (dataState) => dataState.rowsPerPage);
