import { Box, Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import HowToRegIcon from '@mui/icons-material/HowToReg';

const ConformationPopup = ({ onClickYes, onClickNo, text, ConfirmIcon = HowToRegIcon }) => (
    <Box sx={{ width: '100%' }}>
        <Box sx={{ padding: '10px 5px' }}>
            <Typography>{text}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
            <Button size="small" sx={{ width: '45%' }} startIcon={<ConfirmIcon />} onClick={onClickYes} variant="contained">
                Yes
            </Button>
            <Button variant="outlined" color="error" size="small" startIcon={<CancelIcon />} sx={{ width: '45%' }} onClick={onClickNo}>
                No
            </Button>
        </Box>
    </Box>
);
export default ConformationPopup;
