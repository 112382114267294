import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    baseUrl: 'roleAccess/',
    byroleId: '/roleAccess/by_role_id',
    allscreens: '/roleAccess/screens',
    addAccess: '/roleAccess/add'
};
//
export const getRolesAccessById = async (roleid) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.byroleId}/${roleid}`);
    return res.data;
};
//
export const getAllScreens = async () => {
    const res = await securedAxiosAPI.get(`${securedRoute?.allscreens}`);
    return res.data;
};
//
export const addRolesAccess = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.addAccess}`, data);
    return res.data;
};
