import React from 'react';
import FormField from './FormField';
import { arrayToObject } from 'assets/function utils/functionUtils';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    Select as MUISelect,
    MenuItem,
    FormHelperText,
    OutlinedInput,
    Checkbox,
    ListItemText
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
//
const SelectImpl = React.forwardRef(
    (
        {
            label,
            name = '',
            variant,
            size,
            value,
            onChange,
            onBlur,
            error,
            helperText,
            sx,
            defaultItem,
            items,
            nameField = 'name',
            valueField = 'value',
            className,
            helperReq = true,
            otherChangeHandler = () => {},
            ...restProps
        },
        ref
    ) => {
        const inputId = name.replace('.', '-');
        const keyValueObj = arrayToObject(items, valueField, nameField);
        return (
            <FormControl disabled={restProps?.disabled} variant={variant} size={size} error={error} sx={{ ...sx }} className={className}>
                {label && <InputLabel id={`${inputId}-label`}>{label}</InputLabel>}
                <MUISelect
                    fullWidth
                    displayEmpty
                    labelId={`${inputId}-label`}
                    id={inputId}
                    value={value}
                    onChange={(event) => {
                        onChange(event.target.value);
                        otherChangeHandler(event);
                    }}
                    onBlur={onBlur}
                    label={label}
                    ref={ref}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        if (selected?.length === 0) {
                            return <div style={{ color: '#bfc4cc' }}>{defaultItem[nameField]}</div>;
                        }
                        return keyValueObj[selected];
                    }}
                >
                    <MenuItem disabled key={defaultItem[valueField]} value="">
                        {defaultItem[nameField]}
                    </MenuItem>
                    {items.map((item, index) => (
                        <MenuItem key={item[valueField]} value={item[valueField]}>
                            {item[nameField]}
                        </MenuItem>
                    ))}
                </MUISelect>
                {helperReq && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
);

const Select = React.forwardRef((props, ref) => <FormField ref={ref} Component={SelectImpl} {...props} />);

const MultiSelect = React.forwardRef(
    (
        {
            label,
            name = '',
            variant,
            size,
            value,
            onChange,
            onBlur,
            error,
            helperText,
            sx,
            defaultItem,
            items,
            nameField = 'name',
            valueField = 'value',
            className,
            keyValueObj,
            ...restProps
        },
        ref
    ) => {
        const inputId = name.replace('.', '-');

        return (
            <>
                <FormControl
                    disabled={restProps?.disabled}
                    variant={variant}
                    size={size}
                    error={error}
                    sx={{ ...sx }}
                    className={className}
                    ref={ref}
                >
                    {label && <InputLabel id={`${inputId}-label`}>{label}</InputLabel>}
                    <MUISelect
                        id="multiple-checkbox"
                        multiple
                        displayEmpty
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        value={value}
                        input={<OutlinedInput label={label} />}
                        renderValue={(selected) => {
                            if (selected?.length === 0) {
                                return <div style={{ color: '#bfc4cc' }}>{defaultItem[nameField]}</div>;
                            }

                            const selectNameArray = selected.map((item) => keyValueObj[item]);

                            return selectNameArray.join(' /  ');
                        }}
                        MenuProps={MenuProps}
                    >
                        {defaultItem?.[nameField] && (
                            <MenuItem disabled key={defaultItem[valueField]} value={defaultItem[valueField]}>
                                {defaultItem[nameField]}
                            </MenuItem>
                        )}
                        {items?.map((item) => (
                            <MenuItem key={item[valueField]} value={item[valueField]}>
                                <Checkbox checked={value.indexOf(item[valueField]) > -1} />
                                <ListItemText primary={item.name} />
                            </MenuItem>
                        ))}
                    </MUISelect>
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            </>
        );
    }
);
export const MUISelectMulti = React.forwardRef((props, ref) => <FormField ref={ref} Component={MultiSelect} {...props} />);

SelectImpl.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    sx: PropTypes.object,
    defaultItem: PropTypes.object,
    items: PropTypes.array,
    nameField: PropTypes.string,
    valueField: PropTypes.string,
    className: PropTypes.string
};
MultiSelect.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    sx: PropTypes.object,
    defaultItem: PropTypes.object,
    items: PropTypes.array,
    nameField: PropTypes.string,
    valueField: PropTypes.string,
    className: PropTypes.string,
    keyValueObj: PropTypes.object
};
export default Select;
