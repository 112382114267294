export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// For view in ui

export const readStringDate = (string) => {
    const dateObj = new Date(string);
    let date = dateObj.getDate();
    const month = monthsArray[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    if (String(date).length === 1) {
        date = `0${date}`;
    }

    return `${date}-${month}-${year}`;
};
// To send the date when its object
export const sendDateFormate = (obj) => {
    const date = obj.getDate();
    const month = obj.getMonth();

    const year = obj.getFullYear();
    return `${year}-${month + 1}-${date}`;
};
/// To send the date when its object
export const sendDateFormateReadStringToSendStrinf = (string) => {
    const dateObj = new Date(string);
    const date = dateObj.getDate();
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();
    return `${year}-${month + 1}-${date}`;
};

export const approvalStatus = [
    {
        name: 'Approve',
        value: 1
    },
    {
        name: 'Reject',
        value: 0
    }
];

export const textFieldValidationsRules = {
    textField: {
        minLength: {
            value: 5,
            message: 'Minimun length 5'
        }
    },
    emailField: {
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: 'Please Enter Valid E-mail Address'
        }
    },
    mobileField: {
        required: ' Contact number required !',
        minLength: {
            value: 10,
            message: 'Please Enter Valid 10 Digit Number.'
        },
        maxLength: {
            value: 15,
            message: 'Please Enter Valid 10 Digit Number.'
        }
    },
    selectField: {
        required: 'Required!'
    },
    dateField: {
        required: ' Required',
        minLength: {
            value: 5,
            message: 'Minimun length 5'
        }
    }
};
export function arrayToObject(array, keyName, valueName) {
    const Obj = {};
    if (array.length > 0) {
        array.forEach((element) => {
            Obj[element[keyName]] = element[valueName];
        });
    }

    return Obj;
}
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
export function stringAvatar(name, styles) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            ...styles
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')?.[1]?.[0] ? name.split(' ')[1][0] : ' '}`
    };
}
export function xnor(a, b) {
    return !(a !== b);
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getImageFormat(buffer) {
    // Check for JPEG format
    if (buffer[0] === 0xff && buffer[1] === 0xd8) {
        return 'image/jpeg';
    }
    // Check for PNG format
    if (buffer[0] === 0x89 && buffer[1] === 0x50 && buffer[2] === 0x4e && buffer[3] === 0x47) {
        return 'image/png';
    }
    // Add more checks for other image formats if needed
    // ...

    // Default to unknown format
    return 'image/octet-stream'; // default to binary
}

export function joinTheNameOnLoop(array, seperator = ' / ', propName = 'name') {
    let conactString = '';
    array.forEach((item, index) => {
        conactString += `${item[propName]} ${index === array.length - 1 ? '' : seperator}`;
    });
    return conactString;
}
export function seprationFunction(oldArrayData, newArrayData) {
    const formArray = [];
    oldArrayData.forEach((oldTestId) => {
        let bool = true;
        newArrayData.forEach((newTestId) => {
            if (oldTestId === newTestId) {
                bool = false;
            }
        });
        if (bool) formArray.push(oldTestId);
    });
    return formArray;
}
