import React from 'react';
import FormField from './FormField';
import { TextField as MUITextField } from '@mui/material';

const MUITextField1 = React.forwardRef(({ otherChangeHandler = () => {}, onChange, ...restProps }, ref) => (
    <MUITextField
        ref={ref}
        onChange={(event) => {
            onChange(event.target.value);
            otherChangeHandler(event);
        }}
        {...restProps}
    />
));

const TextField = React.forwardRef((props, ref) => <FormField ref={ref} Component={MUITextField1} {...props} />);

export default TextField;
