// Saga Effects
import { all, call } from 'redux-saga/effects';
import userDataSaga from './slices/userSaga';
import customerDataSaga from './slices/customer/customerSaga';
import lookupDetailsDataSaga from './slices/lookMaster/lookupDetails.saga';
import testRequestDataSaga from './slices/testRequest/testRequestSaga';
import labTestDataSaga from './slices/lab/labSaga';
import generatedSampleId from './slices/generatedSampleId/generatedSampleIdSaga';
import testTemplateDataSaga from './slices/testTemplate/testTemplate.saga';

export default function* rootSaga() {
    yield all([
        call(userDataSaga),
        call(customerDataSaga),
        call(lookupDetailsDataSaga),
        call(testRequestDataSaga),
        call(labTestDataSaga),
        call(generatedSampleId),
        call(testTemplateDataSaga)
    ]);
}
