import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    setAllUsers,
    startAllUsers,
    startAddingNewUser,
    completedAction,
    loggedInUser,
    logOutUser,
    startLoginUser,
    startRefresh,
    startLogout,
    startDynamicFilter,
    startUserDetailById,
    setUserDetails,
    startUserDataUpdate,
    startDeleteUser,
    startResetCurentPassword,
    startResetUserPasswordByAdmin,
    startUpdatePaswdByCode,
    setloggedInPortal
} from './user';
import {
    addNewUser,
    addUserDetail,
    loginUser,
    refresh,
    logout,
    dynamicFilter,
    userByPagination,
    usersDetailsById,
    updateUserData,
    deleteUsersById,
    resetCurentPassword,
    updatePasswordByCode,
    resetUserPasswordByAdmin,
    customerRefresh
} from 'utils/apis/user';
import { openSnackbar } from './snackbar';
import { setFormNavigate } from './formNavigate/formNavigateSlice';
import { customerLoginUser } from 'utils/apis/customer-portal';
import { resetCustomerUserPasswordByAdmin } from 'utils/apis/customer-user';
// Saga handlers

// Add  New User  Response (1)
function* addingResponseHandler({ payload }) {
    try {
        const { userData, addUserDetailData, signature } = payload;
        yield call(addNewUser, { userData, addUserDetailData, signature });
        //  yield call(addUserDetail, { user_id: insertId, ...addUserDetailData });
        // yield put(startAllUsers());
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'User Added Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//  Add New User Action watcher(1)
function* startAddingNewUserFetchWatcher() {
    yield takeLatest(startAddingNewUser.type, addingResponseHandler);
}
// Login  user Response (2)
function* loginResponseHandler({ payload }) {
    const { labSignIn = false, data, customer_id: parCustomerId } = payload;
    try {
        // let response;
        // if (labSignIn) {
        //     response = yield call(loginUser, data);
        //     yield put(setloggedInPortal('LAB'));
        // } else {
        //     // response = yield call(refresh);
        //     response = yield call(customerLoginUser, { ...data, customer_id: parCustomerId });
        //     yield put(setloggedInPortal('CUSTOMER'));
        // }
        // console.log(response, 'response');
        const response = yield call(loginUser, data);
        const {
            user: { id, email, name, role, role_name: roleName, customer_id: customerId, loginPortal, customer_name: companyName },
            accessToken,
            userRoles
        } = response;
        yield put(setloggedInPortal(loginPortal));
        yield put(
            loggedInUser({ userID: id, email, name, userToken: accessToken, roleId: role, roleName, userRoles, customerId, companyName })
        );
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.response.data,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Login user  watcher(2)
function* startLoginUserFetchWatcher() {
    yield takeLatest(startLoginUser.type, loginResponseHandler);
}
// Refresh user Response  (3)
function* loginRefreshResponseHandler() {
    try {
        const response = yield call(refresh);
        const {
            user: { id, email, name, role, role_name: roleName, customer_id: customerId, loginPortal, customer_name: companyName },
            accessToken,
            userRoles
        } = response;
        yield put(setloggedInPortal(loginPortal));
        yield put(
            loggedInUser({ userID: id, email, name, userToken: accessToken, roleId: role, roleName, userRoles, customerId, companyName })
        );
        yield put(completedAction());
    } catch (error) {
        // yield put(
        //     openSnackbar({
        //         open: true,
        //         message: 'Session Expired',
        //         variant: 'alert',
        //         alert: {
        //             color: 'error'
        //         },
        //         close: false,
        //         actionButton: true
        //     })
        // );
        yield put(completedAction());
    }
}
//   Refresh user  watcher(3)
function* startRefreshFetchWatcher() {
    yield takeLatest(startRefresh.type, loginRefreshResponseHandler);
}

// Logout Response (4)
function* logoutResponseHandler() {
    try {
        yield call(logout);
        yield put(logOutUser());
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Logout Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.response.data,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Logout user  watcher(4)
function* startLogoutWatcher() {
    yield takeLatest(startLogout.type, logoutResponseHandler);
}
// all User  Response (5)
function* allUsersResponseHandler({ payload }) {
    try {
        const response = yield call(userByPagination, payload);
        yield put(setAllUsers(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   All user  watcher(5)
function* startAllUsersWatcher() {
    yield takeLatest(startAllUsers.type, allUsersResponseHandler);
}

//  Dynamic Filter  Response (6)
function* startDynamicFilterResponseHandler({ payload }) {
    try {
        const response = yield call(dynamicFilter, payload);
        yield put(setAllUsers(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Dynamic Filter watcher(6)
function* startstartDynamicFilterWatcher() {
    yield takeLatest(startDynamicFilter.type, startDynamicFilterResponseHandler);
}

//  User Detail By Id  Response (7)
function* startUserDetailByIdrResponseHandler({ payload }) {
    try {
        const response = yield call(usersDetailsById, payload);
        yield put(setUserDetails(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   User Detail By Id watcher(7)
function* startUserDetailByIdWatcher() {
    yield takeLatest(startUserDetailById.type, startUserDetailByIdrResponseHandler);
}

//  update User Data  Response (8)
function* startUserDataUpdateResponseHandler({ payload }) {
    try {
        yield call(updateUserData, payload);
        yield put(
            openSnackbar({
                open: true,
                message: 'User Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(setFormNavigate(true));
        yield put(setUserDetails({}));
        // yield put(startAllUsers());
        //  yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   update User Data watcher(8)
function* startUserDataUpdateWatcher() {
    yield takeLatest(startUserDataUpdate.type, startUserDataUpdateResponseHandler);
}

// Delete User  By Id  Response (9)
function* startDeleteUsersByIdResponseHandler({ payload }) {
    const { filterData, filterStatus, page, rowsPerPage, id } = payload;
    try {
        yield call(deleteUsersById, id);
        if (filterStatus) {
            yield put(startDynamicFilter({ ...filterData, page, rowsPerPage }));
        } else {
            yield put(startAllUsers());
        }
        yield put(
            openSnackbar({
                open: true,
                message: 'User Deleted Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
// Delete  User  By Id watcher(9)
function* startDeleteUsersByIdWatcher() {
    yield takeLatest(startDeleteUser.type, startDeleteUsersByIdResponseHandler);
}
// Reset Current Password Response (10)
function* startResetCurentPasswordResponseHandler({ payload }) {
    try {
        yield call(resetCurentPassword, payload);
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'User Password Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(setFormNavigate(true));
        //  yield put(startAllUsers());
    } catch (error) {
        // alert(error.message);
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Reset Current Password watcher(10)
function* startResetCurentPasswordWatcher() {
    yield takeLatest(startResetCurentPassword.type, startResetCurentPasswordResponseHandler);
}
//
// Update  Password by code Response (11)
function* startUpdatePaswdByCodeResponseHandler({ payload }) {
    try {
        yield call(updatePasswordByCode, payload);
        yield put(
            openSnackbar({
                open: true,
                message: 'User Password Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(setFormNavigate(true));
        //    yield put(startAllUsers());
        //  yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Reset Current Password watcher(11)
function* startUpdatePaswdByCodeWatcher() {
    yield takeLatest(startUpdatePaswdByCode.type, startUpdatePaswdByCodeResponseHandler);
}

// Reset User Password By Admin Response (12)
function* startResetUserPasswordByAdminResponseHandler({ payload }) {
    try {
        const { data, isLabUser } = payload;
        yield call(resetUserPasswordByAdmin, data);
        // if (isLabUser) {
        //     yield call(resetUserPasswordByAdmin, data);
        // } else {
        //     yield call(resetCustomerUserPasswordByAdmin, data);
        // }
        yield put(
            openSnackbar({
                open: true,
                message: 'Password Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(setFormNavigate(true));
        //   yield put(startAllUsers());
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(completedAction());
    }
}
//   Reset User Password By Admin watcher(12)
function* startResetUserPasswordByAdminWatcher() {
    yield takeLatest(startResetUserPasswordByAdmin.type, startResetUserPasswordByAdminResponseHandler);
}

// Accumulator
export default function* userDataSaga() {
    yield all([
        call(startAddingNewUserFetchWatcher),
        call(startLoginUserFetchWatcher),
        call(startRefreshFetchWatcher),
        call(startLogoutWatcher),
        call(startAllUsersWatcher),
        call(startstartDynamicFilterWatcher),
        call(startUserDetailByIdWatcher),
        call(startUserDataUpdateWatcher),
        call(startDeleteUsersByIdWatcher),
        call(startResetCurentPasswordWatcher),
        call(startUpdatePaswdByCodeWatcher),
        call(startResetUserPasswordByAdminWatcher)
    ]);
}
