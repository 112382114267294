// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    isLoading: false,
    testRequestTemplates: [],
    testRequestTemplatesData: {}
};

const slice = createSlice({
    name: 'testRequestTemplate',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startAddAndUpdateTestTemplate(state, action) {
            state.isLoading = true;
        },
        // startTestRequestStatusUpdate(state, action) {
        //     state.isLoading = true;
        // },
        startListTemplate(state, action) {
            state.isLoading = true;
        },
        setTestRequestTemplates(state, action) {
            state.testRequestTemplates = action.payload;
        },
        startTestTemplateDetails(state, action) {
            state.isLoading = true;
        },
        setTestTemplateDetails(state, action) {
            state.testRequestTemplatesData = action.payload;
        },
        startUpdateStatus(state, action) {
            state.isLoading = true;
        },
        // ////////////////////
        completedAction(state, action) {
            state.isLoading = false;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    completedAction,
    //
    startAddAndUpdateTestTemplate,
    startTestTemplateDetails,
    startListTemplate,
    setTestRequestTemplates,
    setTestTemplateDetails,
    startUpdateStatus
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors
const testTemplateStates = (state) => state.testTemplate;
/// Data Loading Selector
export const isLoadingSelector = createSelector([testTemplateStates], (testTemplateDataState) => testTemplateDataState.isLoading);
// Page Selector
export const pageSelector = createSelector([testTemplateStates], (testTemplateDataState) => testTemplateDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([testTemplateStates], (testTemplateDataState) => testTemplateDataState.rowsPerPage);
//
// list
export const testTemplatesSelector = createSelector(
    [testTemplateStates],
    (testTemplateDataState) => testTemplateDataState.testRequestTemplates
);
// details data
export const testTemplateDetailsSelector = createSelector(
    [testTemplateStates],
    (testTemplateDataState) => testTemplateDataState.testRequestTemplatesData
);
