import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const FormHead = ({ headText, required, sx, ...otherProps }) => (
    <Typography variant="body2" gutterBottom {...otherProps}>
        <span
            style={{
                paddingBottom: '0.5rem',
                ...sx
            }}
        >
            {headText}
        </span>
        {required && <span style={{ color: 'red' }}>*</span>}:
    </Typography>
);
FormHead.propTypes = {
    headText: PropTypes.string,
    required: PropTypes.bool,
    sx: PropTypes.object
};
export default FormHead;
