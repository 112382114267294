import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    startDeleteCustomer,
    startUsersByCustomerId,
    setCustomerUsers,
    setCustomers,
    startAllCustomer,
    completedAction,
    startAddingNewCustomer,
    customerDetials,
    startCustomerDetailsOnID,
    startAddingCustomerUser,
    startDynamicFilter,
    setcUserData,
    startCUserData,
    startUpdatingcUser
} from './customer';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';
import { addNewCustomer, getCustomerDetails, dynamicFilter, deleteCustomer, getCustomerByPagination } from 'utils/apis/customer';
import { addCustomerUser, customerUsers, getCustomerUsersByPaginationAndCustomerId, updateUserDetail } from 'utils/apis/customer-user';
import { openSnackbar } from '../snackbar';
// Saga handlers

// Add  New Customer  Response (1)
function* addingResponseHandler({ payload }) {
    try {
        yield call(addNewCustomer, payload);
        yield put(startAllCustomer());
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Customer Added Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Add New Customer Action watcher(1)
function* startAddingNewCustomerFetchWatcher() {
    yield takeLatest(startAddingNewCustomer.type, addingResponseHandler);
}

// Customer Details By ID Response (2)
function* customerDetailsResponseHandler({ payload }) {
    try {
        const response = yield call(getCustomerDetails, payload);
        yield put(customerDetials(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Customer Details By ID Watcher (2)
function* startCustomerDetailsByIDFetchWatcher() {
    yield takeLatest(startCustomerDetailsOnID.type, customerDetailsResponseHandler);
}

// Customers Response (3)
function* customersResponseHandler() {
    try {
        const response = yield call(getCustomerByPagination);
        yield put(setCustomers(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Customers Watcher (3)
function* startCustomersFetchWatcher() {
    yield takeLatest(startAllCustomer.type, customersResponseHandler);
}
// Delete Customers Response (4)
function* deleteCustomersResponseHandler({ payload }) {
    const { filterData, filterStatus, page, rowsPerPage, id } = payload;
    try {
        yield call(deleteCustomer, id);
        if (filterStatus) {
            yield put(startDynamicFilter({ ...filterData, page, rowsPerPage }));
        } else {
            yield put(startAllCustomer());
        }
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Customer Deleted Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Delete Customers  Watcher (4)
function* startDeleteCustomerWatcher() {
    yield takeLatest(startDeleteCustomer.type, deleteCustomersResponseHandler);
}
//  Dynamic Filter  Response (5)
function* startDynamicFilterResponseHandler({ payload }) {
    try {
        const response = yield call(dynamicFilter, payload);
        yield put(setCustomers(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Dynamic Filter watcher(5)
function* startstartDynamicFilterWatcher() {
    yield takeLatest(startDynamicFilter.type, startDynamicFilterResponseHandler);
}
// ================================= customer users action =====================================================////
// Add  New Customer User  Response (1)
function* addingCustomerUserResponseHandler({ payload }) {
    try {
        const { customer_id: id } = payload;
        yield call(addCustomerUser, payload);
        yield put(startUsersByCustomerId(id));
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'User Added Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Add New Customer User Action watcher(1)
function* startAddingNewCustomerUserFetchWatcher() {
    yield takeLatest(startAddingCustomerUser.type, addingCustomerUserResponseHandler);
}
//  Customer Users  Response (2)
function* customerUsersResponseHandler({ payload }) {
    try {
        const response = yield call(getCustomerUsersByPaginationAndCustomerId, payload);
        yield put(setCustomerUsers(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Customer Users Action watcher(2)
function* startCustomerUsersByIDFetchWatcher() {
    yield takeLatest(startUsersByCustomerId.type, customerUsersResponseHandler);
}
//  Customer Users Data by user id  Response (3)
function* startCUserDataResponseHandler({ payload }) {
    try {
        const response = yield call(customerUsers, payload);
        yield put(setcUserData(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Customer Users Action by user id watcher(3)
function* startCUserDataFetchWatcher() {
    yield takeLatest(startCUserData.type, startCUserDataResponseHandler);
}

// Updating User Details  Response (4)
function* updatinUserResponseHandler({ payload }) {
    try {
        yield call(updateUserDetail, payload);
        //   yield put(startAllCustomer());
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Updating User Details Action watcher(4)
function* startUpdatingcUserFetchWatcher() {
    yield takeLatest(startUpdatingcUser.type, updatinUserResponseHandler);
}

// setcUserData,
// startCUserData

// Accumulator
export default function* customerDataSaga() {
    yield all([
        call(startDeleteCustomerWatcher),
        call(startCustomerUsersByIDFetchWatcher),
        call(startAddingNewCustomerFetchWatcher),
        call(startCustomerDetailsByIDFetchWatcher),
        call(startAddingNewCustomerUserFetchWatcher),
        call(startCustomersFetchWatcher),
        call(startstartDynamicFilterWatcher),
        call(startCUserDataFetchWatcher),
        call(startUpdatingcUserFetchWatcher)
    ]);
}
