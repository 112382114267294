import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    getAnalysisGroupRemark: '/analysisGroupRemark/getGroupRemark',
    updateAnalysisGroupRemark: '/analysisGroupRemark/updateAnalysisGroupRemakr',
    findTheCertificatesByLabTestId: '/analysisGroupRemark/findTheCertificatesByLabTestId',
    // customer Portal
    customerGetAnalysisGroupRemark: 'customerPortal/customerFindByLabTestIdAndTestType',
    downloadGroupCertificate: '/generatePDF/download'
};
export const getAnalysisGroupRemark = async (data) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.getAnalysisGroupRemark, data);
    } else if (loggedInPortal === 'CUSTOMER') {
        //
        res = await securedAxiosAPI.post(securedRoute?.customerGetAnalysisGroupRemark, { ...data, customer_id: customerId });
    }

    return res.data;
};
export const updateAnalysisGroupRemark = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateAnalysisGroupRemark, data);
    return res.data;
};
export const analysisGroupRemarkByLabId = async (labId) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.findTheCertificatesByLabTestId}/${labId}`);
    return res.data;
};
export const downloadGroupCertificate = async (fileName) => {
    const res = await securedAxiosAPI.post(securedRoute?.downloadGroupCertificate, { name: fileName }, { responseType: 'blob' });
    return res.data;
};
