import { securedAxiosAPI } from 'utils/axios';

const securedRoute = {
    validate: 'specimenQuality/add',
    clientUsersByCustomerID: 'clientUser'
};
export const addSpecimenValidation = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.validate, data);
    return res.data;
};
