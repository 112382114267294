import {
    Divider,
    Grid,
    Typography,
    useTheme,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CardHeader,
    styled
} from '@mui/material';
import * as React from 'react';
import { gridSpacing } from 'store/constant';
import { SumbitHandler } from './index';
import { SumbitHandler as EditSumbitHandler } from '../edit-test-request/index';
import { PreviewFormWrapper } from './indexStyle';
import { readStringDate, arrayToObject } from 'assets/function utils/functionUtils';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

import { useLocation } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const MultiListTableComp = ({ data }) => (
    <TableRow size="small">
        <TableCell size="small">{data?.name}</TableCell>
        <TableCell align="right" size="small">
            {data?.bool ? <DoneIcon size="small" color="success" /> : <CloseIcon size="small" color="error" />}
        </TableCell>
    </TableRow>
);
export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));
export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));
export const dataGenerator = (array, valueArray, valueFeild = 'id') =>
    array.map((item) => {
        let bool = false;
        if (valueArray.indexOf(item[valueFeild]) >= 0) {
            bool = true;
        } else {
            bool = false;
        }
        return { ...item, bool };
    });
export default function PreviewForm({
    formStates,
    prevDetails,
    microbiology,
    allergens,
    value,
    handleChange,
    nops,
    sampleType,
    animalFeed,
    customers,
    waterActivity,
    mycotoxin,
    moistureAnalysis,
    pH
}) {
    const sampleObj = arrayToObject(sampleType, 'id', 'name');
    const customerObj = arrayToObject(customers, 'id', 'name');
    const [expanded, setExpanded] = React.useState('');

    const handleChangeAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const theme = useTheme();
    const pathName = useLocation().pathname;
    //  console.log(formStates, 'PreviewFormWrapper');
    return (
        <PreviewFormWrapper>
            <Grid container>
                <Grid item xs={12} sx={{ mb: '1rem' }}>
                    <Typography variant="subtitle1">Sample Information </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Table>
                        <TableHead bgcolor={theme.palette.warning.light}>
                            <TableRow size="small">
                                <TableCell size="small">Name</TableCell>
                                <TableCell align="right" size="small">
                                    Detail
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow size="small">
                                <TableCell size="small">Customer / Company :</TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{customerObj[formStates?.customer_id]}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow size="small">
                                <TableCell size="small">Sent By:</TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{formStates?.sent_by} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow size="small">
                                <TableCell size="small">Product Name :</TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{formStates?.p_name} </Typography>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell size="small">Product Code : </TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{formStates?.p_code} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">Batch Number :</TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="subtitle1">{formStates?.batch} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">Sample Type :</TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{sampleObj[formStates?.sample_type]} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">Manufacture Date : </TableCell>
                                <TableCell align="right" size="small">
                                    <Typography variant="subtitle1">{readStringDate(formStates?.mfg_date)} </Typography>
                                </TableCell>
                            </TableRow>
                            {formStates?.bbf_date && (
                                <TableRow>
                                    <TableCell size="small">Best Before Date :</TableCell>
                                    <TableCell align="right" size="small">
                                        <Typography variant="subtitle1">{readStringDate(formStates?.bbf_date)} </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell size="small">Customer Reference :</TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="subtitle1">
                                        {formStates?.customer_ref ? formStates?.customer_ref : '- - - - - - - -'}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">Pallet ID :</TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="subtitle1">
                                        {formStates?.pallet_id ? formStates?.pallet_id : '- - - - - - - -'}{' '}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size="small">Comment :</TableCell>
                                <TableCell size="small" align="right">
                                    <Typography variant="subtitle1">
                                        {formStates?.comment ? formStates?.comment : '- - - - - - - -'}{' '}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.grey[50]}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordian('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Animal Feed Intended</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(animalFeed, formStates.animal_feed).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordian('panel2')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Allergens </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(allergens, formStates.allergens).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordian('panel3')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Microbiology </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(microbiology, formStates.microbiology).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordian('panel4')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>NOPS Analysis </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(nops, formStates.nops).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordian('panel5')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Water Activity </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(waterActivity, formStates.waterActivity).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChangeAccordian('panel6')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Mycotoxin </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(mycotoxin, formStates.mycotoxin).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChangeAccordian('panel7')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Moisture Analysis </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(moistureAnalysis, formStates.moistureAnalysis).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChangeAccordian('panel8')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>pH </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGenerator(pH, formStates.pH).map((item) => (
                                        <MultiListTableComp theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12} md={12} sx={{ marginTop: gridSpacing }}>
                    {pathName === '/sample-reception/test-requests/add-request' ? (
                        <SumbitHandler value={value} handleChange={handleChange} prevDetails={prevDetails} formStates={formStates} />
                    ) : (
                        <EditSumbitHandler value={value} handleChange={handleChange} prevDetails={prevDetails} formStates={formStates} />
                    )}
                </Grid>
            </Grid>
        </PreviewFormWrapper>
    );
}
