// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const dayjs = require('dayjs');

const initialState = {
    page: 0,
    rowsPerPage: 10,

    isLoading: false,
    isLoading2: false,
    message: '',

    testRequest: [],
    testRequestData: {},
    testRequestDetailsByTestRequestId: [],
    columnFilter: {
        sampleId: {
            appliedList: [],
            checkedList: []
        },
        products: {
            appliedList: [],
            checkedList: []
        },
        sampleType: {
            appliedList: [],
            checkedList: []
        },
        testType: {
            appliedList: [],
            checkedList: []
        },
        batch: {
            appliedList: [],
            checkedList: []
        },
        customers: {
            appliedList: [],
            checkedList: []
        },
        status: {
            appliedList: [],
            checkedList: []
        },
        tatDate: {
            appliedList: [],
            checkedList: [
                {
                    startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                    endDate: dayjs(new Date()).format('MM-DD-YYYY')
                }
            ]
        },
        reqDate: {
            appliedList: [],
            checkedList: [
                {
                    startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                    endDate: dayjs(new Date()).format('MM-DD-YYYY')
                }
            ]
        }
    }
};

const slice = createSlice({
    name: 'testRequest',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination

        // table filters
        setColumnCheckedList(state, action) {
            const { columnName, data, status, listType, newValue } = action.payload;
            if (listType === 'DATE' && data) {
                //
                // data =>  startDate or endDate
                //
                let tempColumnFilterOne = { ...state.columnFilter };

                tempColumnFilterOne[columnName].checkedList[0][data] = newValue;
                state.columnFilter = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else if (status) {
                // status refers to checkbox status
                // new item is checked
                // if a new item is checked it will always enter the checked list
                let tempColumnFilterOne = { ...state.columnFilter };
                tempColumnFilterOne[columnName].checkedList.push(data);

                state.columnFilter = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // if the item is getting unchecked that may be in the appliedList or checkedList ( we need to hydrate the checklist for consistency )
                // but need to keep in mind untill apply button is pressed don't remove or add to the appliedList
                let tempColumnFilterOne = { ...state.columnFilter };
                let i = 0;
                // eslint-disable-next-line no-plusplus
                for (i; i < tempColumnFilterOne[columnName].checkedList.length; i++) {
                    if (tempColumnFilterOne[columnName].checkedList[i].id === data.id) {
                        break;
                    }
                }
                tempColumnFilterOne[columnName].checkedList.splice(i, 1);
                // if (tempColumnFilterOne[columnName].checkedList.length === 0) {
                //     // scan the applied list
                //     // tempColumnFilterOne[columnName].appliedList
                //     let i = 0;
                //     // eslint-disable-next-line no-plusplus
                //     for (i; i < tempColumnFilterOne[columnName].appliedList.length; i++) {
                //         if (tempColumnFilterOne[columnName].appliedList[i].id === data.id) {
                //             break;
                //         }
                //     }
                //     tempColumnFilterOne[columnName].appliedList.splice(i, 1);
                // } else if (tempColumnFilterOne[columnName].appliedList.length === 0) {
                //     // scan the checked list
                //     let i = 0;
                //     // eslint-disable-next-line no-plusplus
                //     for (i; i < tempColumnFilterOne[columnName].checkedList.length; i++) {
                //         if (tempColumnFilterOne[columnName].checkedList[i].id === data.id) {
                //             break;
                //         }
                //     }
                //     tempColumnFilterOne[columnName].checkedList.splice(i, 1);
                // } else {
                //     // scan both of the list
                //     let i = 0;
                //     let mergerdArray = [...tempColumnFilterOne[columnName].checkedList, ...tempColumnFilterOne[columnName].appliedList];
                //     // eslint-disable-next-line no-plusplus
                //     for (i; i < mergerdArray.length; i++) {
                //         if (mergerdArray[i].id === data.id) {
                //             break;
                //         }
                //     }

                //     if (i <= tempColumnFilterOne[columnName].checkedList.length - 1) {
                //         tempColumnFilterOne[columnName].checkedList.splice(i, 1);
                //     } else {
                //         tempColumnFilterOne[columnName].appliedList.splice(i, 1);
                //     }
                //     mergerdArray = [];
                // }
                state.columnFilter = { ...tempColumnFilterOne };

                tempColumnFilterOne = {};
            }
        },
        clearUnsavedCheckList(state, action) {
            const { columnName, listType } = action.payload;
            if (columnName && listType !== 'DATE') {
                let tempColumnFilterOne = { ...state.columnFilter };
                tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                state.columnFilter = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // eslint-disable-next-line no-lonely-if
                if (columnName) {
                    // DATE list-type
                    let tempColumnFilterOne = { ...state.columnFilter };
                    if (tempColumnFilterOne[columnName].appliedList.length > 0) {
                        // make checklist same as applied list on close of menu
                        tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                        state.columnFilter = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    } else {
                        tempColumnFilterOne[columnName].checkedList = [
                            {
                                startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                                endDate: dayjs(new Date()).format('MM-DD-YYYY')
                            }
                        ];
                        state.columnFilter = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    }
                }
            }
        },
        updateAppliedList(state, action) {
            const { updateType, columnName, listType } = action.payload;
            let tempColumnFilterOne = { ...state.columnFilter };
            if (updateType === 'UPDATE') {
                // new checked list moves to the applied list
                // need to avoid dublicate values
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [
                        {
                            startDate: tempColumnFilterOne[columnName].checkedList[0].startDate,
                            endDate: tempColumnFilterOne[columnName].checkedList[0].endDate
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList.forEach((item) => {
                        //  if (!tempExistingAppliedListObj[item.id])
                        tempColumnFilterOne[columnName].appliedList.push(item);
                    });
                }

                state.columnFilter = { ...tempColumnFilterOne };
                // tempExistingAppliedListObj = {};
            } else if (updateType === 'CLEAR') {
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [
                        {
                            startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                            endDate: dayjs(new Date()).format('MM-DD-YYYY')
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [];
                }
            }
            tempColumnFilterOne = {};
        },

        // table filters
        startAddingNewTest(state, action) {
            state.isLoading = true;
        },
        startTestRequestStatusUpdate(state, action) {
            state.isLoading = true;
        },
        startAllTestRequest(state, action) {
            state.isLoading = true;
            state.isLoading2 = true;
        },
        setTestRequest(state, action) {
            state.testRequest = action.payload;
        },
        startTestRequestData(state, action) {
            state.isLoading = true;
        },
        setTestRequestData(state, action) {
            state.testRequestData = action.payload;
        },
        startDeleteTestByID(state, action) {
            state.isLoading = true;
        },
        startTestRequestDataByFilter(state, action) {
            state.isLoading = true;
            state.isLoading2 = true;
        },
        // // Details Table Data
        startUpdateTestRequestData(state, action) {
            state.isLoading = true;
        },
        startTestRequestDetailsByTestRequestId(state, action) {
            state.isLoading = true;
        },
        setTestRequestDetailsByTestRequestId(state, action) {
            state.testRequestDetailsByTestRequestId = action.payload;
        },
        startAddMoreTestReqDetail(state, action) {
            state.isLoading = true;
        },
        startUpdateIterationOnTestReq(state, action) {
            state.isLoading = true;
        },
        startUpdateCustomer(state, action) {
            state.isLoading = true;
        },
        // ////////////////////
        completedAction(state, action) {
            state.isLoading = false;
            state.isLoading2 = false;
        }
    }
});
// Actions Exports
export const {
    setPage,
    setRowsPerPage,
    // filters
    setColumnCheckedList,
    clearUnsavedCheckList,
    updateAppliedList,
    ///
    startAddingNewTest,
    startTestRequestStatusUpdate,
    startAllTestRequest,
    setTestRequest,
    startDeleteTestByID,
    startTestRequestData,
    setTestRequestData,
    startTestRequestDataByFilter,
    startUpdateTestRequestData,
    startTestRequestDetailsByTestRequestId,
    setTestRequestDetailsByTestRequestId,
    startAddMoreTestReqDetail,
    startUpdateIterationOnTestReq,
    startUpdateCustomer,
    completedAction
} = slice.actions;
// Reducer
export default slice.reducer;

// selectors

const testRequestsStates = (state) => state.testRequests;
/// Data Loading Selector
export const isLoadingSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.isLoading);
export const isLoading2Selector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.isLoading2);
// Test-Requests Selector
export const testRequestsSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.testRequest);
// Test-Requests-data
export const testRequestDataSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.testRequestData);
// Test-Request-Details-By-Test_request-id
export const testRequestDetailssSelector = createSelector(
    [testRequestsStates],
    (testRequesDataState) => testRequesDataState.testRequestDetailsByTestRequestId
);
// Page Selector
export const pageSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.rowsPerPage);
//  Filters

export const columnFilterSelector = createSelector([testRequestsStates], (testRequesDataState) => testRequesDataState.columnFilter);
