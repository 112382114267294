import * as React from 'react';
// material-ui
import { Box, Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MyTextField from 'ui-component/MuiReusableComponents/MuiFormComponents/TextField.Component';
import MaterialUIPickers from 'ui-component/MuiReusableComponents/MuiFormComponents/DatePicker/date.picker.component';
import MySelect, { MUISelectMulti } from 'ui-component/MuiReusableComponents/MuiFormComponents/Select.component';
import { FormProvider, useForm } from 'react-hook-form';
import { SumbitHandler } from './index';
import { arrayToObject } from 'assets/function utils/functionUtils';
import FormHead from 'ui-component/MuiReusableComponents/FormHeadText/formHeadText';
import { gridSpacing } from 'store/constant';
import PopupDialogs from 'ui-component/MuiReusableComponents/MuiPopupDialog/new-popup';
import CancelIcon from '@mui/icons-material/Cancel';
import { addLookUpDetails } from 'utils/apis/lookup';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { getProductsMappedWithCode } from 'utils/apis/productProductCode';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { customerDetailsSelector } from 'store/slices/customer/customer';
import { testTemplateDetailsSelector } from 'store/slices/testTemplate/testTemplate.slice';

export default function SampleInfo({
    value,
    handleChange,
    sampleType,
    animalFeed,
    setFormStates,
    formStates,
    customer,
    selectRecordType,
    adHoc,
    genSampleDetails,
    loggedInPortal
}) {
    // const customer = useSelector(customersSelector);
    const [open, setOpen] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [newProduct, setNewProduct] = React.useState('');
    const [defaultItem, setDefaultItem] = React.useState({ id: -1, name: 'Select Customer / Company ' });
    const customerDetails = useSelector(customerDetailsSelector);
    //  const [productDescription, setProductDescription] = React.useState('');
    //  const [pCode, setPcode] = React.useState('');
    const [productDetails, setProductDetails] = React.useState({
        pCode: '',
        description: ''
    });
    const [newProductIsAdding, setNewProductisAdding] = React.useState(false);
    const { templateData, templateDetails } = useSelector(testTemplateDetailsSelector);
    // const bbfDate = new Date();
    // bbfDate.setYear(bbfDate.getFullYear() + 1);
    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            customer_id: '',
            p_name: '',
            p_code: '',
            batch: '',
            mfg_date: new Date(),
            bbf_date: null,
            st_date: new Date(),
            animal_feed: [],
            sample_type: '',
            customer_ref: '',
            pallet_id: '',
            comment: '',
            ...formStates
        }
    });
    const onChangeProductName = (event) => {
        const { name, value } = event.target;
        setProductDetails((prev) => {
            prev[name] = value;
            return { ...prev };
        });
    };
    const animalFedObj = arrayToObject(animalFeed, 'id', 'name');
    const onSubmit = (data) => {
        if (data?.bbf_date > data?.mfg_date || !data?.bbf_date) {
            setFormStates((prev) => {
                const updObj = { ...prev, ...data, autocompleteProduct: prev?.autocompleteProduct, generated_id: genSampleDetails?.id };
                return updObj;
            });
            handleChange({}, value + 1);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'The Best Before Date should not precede the Manufacturing Date.',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false,
                    actionButton: false
                })
            );
        }
    };

    const filter = createFilterOptions();
    // Fetch Products List
    async function fetchProducts() {
        const response = await getProductsMappedWithCode();

        setProducts(response?.map((data) => ({ ...data, p_name: `${data?.p_code} - ${data?.p_name}` })));
    }
    // console.log(products, 'FIND-PRODUCTS');
    // Add new Product and Fetch Products List
    const onSubmitNewProduct = async () => {
        if (productDetails.pCode.trim().length === 0) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Product Code Required',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false,
                    actionButton: false
                })
            );
        } else {
            setNewProductisAdding(true);
            try {
                const response = await addLookUpDetails({
                    id: 0,
                    lookup_id: 8,
                    description: productDetails.description,
                    pCode: productDetails.pCode,
                    name: newProduct,
                    status: 1
                });
                fetchProducts();
                form.setValue('p_name', newProduct);
                form.setValue('p_code', response.data?.productCode);
                const newProductData = { p_name: newProduct, p_code: response.data?.productCode, id: response.data?.id };
                setProductDetails({ pCode: '', description: '' });
                //   setProductDescription('');
                setNewProduct('');
                setFormStates((prev) => ({ ...prev, autocompleteProduct: newProductData }));
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Product Added Successfully ',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false,
                        actionButton: false
                    })
                );
                setOpen(false);
                setNewProductisAdding(false);
            } catch (error) {
                setNewProductisAdding(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: error.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false,
                        actionButton: false
                    })
                );
            }
        }
    };
    React.useEffect(() => {
        fetchProducts();
        if (loggedInPortal === 'CUSTOMER' && customer.length > 0) {
            setDefaultItem(customer[0]);
        }
    }, []);
    React.useEffect(() => {
        if (!open) {
            setProductDetails({ pCode: '', description: '' });
        }
    }, [open]);
    React.useEffect(() => {
        if (selectRecordType === 'ALREADY-SAMPLE' && adHoc) {
            form.setValue('customer_id', genSampleDetails?.customer_id);
            if (genSampleDetails?.p_map_id > 0) {
                form.setValue('p_code', genSampleDetails?.p_code);
                form.setValue('p_name', genSampleDetails?.p_name);
                form.setValue('generated_id', genSampleDetails?.id);
            }
        } else if (selectRecordType === 'ALREADY-TEMPLATE' && adHoc) {
            form.setValue('customer_id', templateData?.customer_id);
            //    form.setValue('p_code', templateData?.p_code ? templateData?.p_code : '');
            //  form.setValue('p_name', templateData?.p_name ? templateData?.p_name : '');
        }
        if (loggedInPortal === 'CUSTOMER') {
            form.setValue('customer_id', customerDetails?.id);
        }
    }, [selectRecordType, adHoc, genSampleDetails, loggedInPortal, customerDetails, templateData, templateDetails]);

    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={0} columnSpacing={gridSpacing}>
                        <Grid item xs={12}>
                            <FormHead headText="Customer / Company " required />
                            <MySelect
                                fullWidth
                                name="customer_id"
                                sx={{ flexGrow: '1', minWidth: '100%' }}
                                rules={{
                                    required: 'Required '
                                }}
                                disabled={
                                    (selectRecordType === 'ALREADY-SAMPLE' && adHoc) ||
                                    loggedInPortal === 'CUSTOMER' ||
                                    (selectRecordType === 'ALREADY-TEMPLATE' && adHoc)
                                }
                                valueField="id"
                                items={customer}
                                defaultItem={defaultItem}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText=" Product Name  " required />
                            <Autocomplete
                                value={formStates?.autocompleteProduct}
                                error
                                displayEmpty
                                disabled={selectRecordType === 'ALREADY-SAMPLE' && adHoc && genSampleDetails?.p_map_id > 0}
                                onChange={(event, newValue) => {
                                    if (newValue && newValue.inputValue) {
                                        setOpen(true);
                                        setNewProduct(newValue.inputValue);
                                    } else {
                                        setFormStates((prev) => ({ ...prev, autocompleteProduct: newValue }));
                                        form.setValue('p_name', newValue?.p_name);
                                        form.setValue('p_code', newValue?.p_code);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                        filtered.push({
                                            inputValue: params.inputValue,
                                            p_name: `Add New Product : "${params.inputValue}"`
                                        });
                                    }

                                    return filtered;
                                }}
                                options={products}
                                getOptionLabel={(option) => {
                                    // e.g. value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option.p_name;
                                    }
                                    if (option.inputValue) {
                                        return option.p_name;
                                    }
                                    return option.p_name;
                                }}
                                renderInput={(params) => {
                                    // console.log(params, 'view Param');
                                    if (params.inputProps.value === 'undefined') params.inputProps.value = '';
                                    return (
                                        <TextField
                                            {...params}
                                            placeholder="Select Product Name"
                                            sx={{
                                                height: '50px !important',
                                                boxSizing: 'border-box'
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Product Code " required={false} />
                            <MyTextField
                                type="text"
                                placeholder=" Product Code "
                                name="p_code"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    focus: true
                                }}
                                selectOnFocus
                                rules={{
                                    required: 'Required'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Batch Number " required />
                            <MyTextField
                                type="text"
                                name="batch"
                                placeholder="Enter Batch Number"
                                fullWidth
                                rules={{
                                    required: 'Required',
                                    minLength: {
                                        value: 5,
                                        message: 'Minimun length 5'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Sample Type " required />
                            <MySelect
                                fullWidth
                                name="sample_type"
                                placeholder="Sample Type *"
                                sx={{ flexGrow: '1', minWidth: '100%' }}
                                rules={{
                                    required: 'Required '
                                }}
                                valueField="id"
                                items={sampleType}
                                defaultItem={{ id: -1, name: 'Select Sample Type' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Animal Feed Intended For" required />

                            <MUISelectMulti
                                fullWidth
                                displayEmpty
                                name="animal_feed"
                                keyValueObj={animalFedObj}
                                sx={{ flexGrow: '1', minWidth: '100%' }}
                                rules={{
                                    required: 'Required '
                                }}
                                valueField="id"
                                defaultItem={{ id: -1, name: 'Select Animal Feed Intended For' }}
                                items={animalFeed}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Sample Taken Date" required />
                            <MaterialUIPickers fullWidth name="st_date" inputFormat="dd-MMM-yyyy" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Manufacture Date" required />
                            <MaterialUIPickers fullWidth name="mfg_date" inputFormat="dd-MMM-yyyy" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormHead headText="Best Before Date" />
                            <MaterialUIPickers fullWidth name="bbf_date" inputFormat="dd-MMM-yyyy" />
                        </Grid>
                        <Grid item xs={12} sm={6} mt="20px">
                            <FormHead headText="Customer Reference " />
                            <MyTextField
                                type="text"
                                name="customer_ref"
                                placeholder="Enter Customer Reference"
                                fullWidth
                                rules={{
                                    //  required: 'Required',
                                    maxLength: {
                                        value: 30,
                                        message: 'Maximum Char. length 30'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} mt="20px">
                            <FormHead headText="Pallet ID " />
                            <MyTextField
                                type="text"
                                name="pallet_id"
                                placeholder="Enter Pallet ID"
                                fullWidth
                                rules={{
                                    //  required: 'Required',
                                    maxLength: {
                                        value: 18,
                                        message: 'Maximum Char. length 18'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormHead headText="Comment " />
                            <MyTextField
                                type="text"
                                name="comment"
                                multiline
                                rows={4}
                                placeholder="Enter Comment"
                                fullWidth
                                rules={{
                                    // required: 'Required',
                                    maxLength: {
                                        value: 399,
                                        message: 'Maximum Char. length 400'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: gridSpacing }}>
                            <SumbitHandler value={value} handleChange={handleChange} />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
            <PopupDialogs
                title="This is a New Product - Whould you like to add this to our list ?"
                needTitleBar
                size="md"
                open={open}
                setOpen={setOpen}
            >
                <Grid container rowSpacing={0} columnSpacing={gridSpacing}>
                    {/* Add a New Product to List <Grid item xs={12}>
                        <Box sx={{ padding: '10px 5px' }}>
                            <Typography> Do you want a Product in our Product list? </Typography>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Box sx={{ margin: '10px' }}>
                            <FormHead headText=" New Product " required={false} />
                            <TextField
                                id="outlined-read-only-product"
                                fullWidth
                                variant="standard"
                                // label="New Product Name"
                                value={newProduct}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ margin: '10px' }}>
                            <FormHead headText=" Product Code " required />
                            <TextField
                                id="product"
                                placeholder="Enter Product Code"
                                fullWidth
                                name="pCode"
                                onChange={onChangeProductName}
                                value={productDetails.pCode}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ margin: '10px' }}>
                            <FormHead headText="Product Description " required />
                            <TextField
                                id="product-description"
                                fullWidth
                                name="description"
                                value={productDetails.description}
                                onChange={onChangeProductName}
                                multiline
                                rows={2}
                                placeholder="Enter Product Description"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                            <Button
                                size="small"
                                sx={{ width: '45%' }}
                                disabled={newProductIsAdding}
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={onSubmitNewProduct}
                            >
                                Add To List
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => setOpen(false)}
                                color="error"
                                size="small"
                                fo
                                startIcon={<CancelIcon />}
                                sx={{ width: '45%' }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </PopupDialogs>
        </>
    );
}
