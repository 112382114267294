import { PDFViewer } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { labTestDetailsSelector, startLabTestDetailByTestRequestId } from 'store/slices/lab/lab';
import { getAnalysisGroupRemark } from 'utils/apis/analysisGroupRemark';
import { getByTestTypeAndTestReq } from 'utils/apis/calibrationValue';
import { getGroupedTestResultByLabTestIdAndTestType } from 'utils/apis/lab-test-details';
import { getRequestedTestByStatus } from 'utils/apis/testRequestDetail';
import PdfGenGroup from 'views/pages/manage-lab-test/ResultGeneration/ResultViewPrintGroup/PdfGenGroup';

const ViewCertificate = () => {
    const { test_request_id: testRequestId, test_type: testType, lab_test_id: labTestId } = useParams();
    const dispatch = useDispatch();
    const [groupRemarkData, setGroupRemarkData] = useState({});
    const [labTestDetails, setLabTestDetails] = useState({});
    const [calibrationVlaueList, setCalibrationList] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const labTestData = useSelector(labTestDetailsSelector);
    const fetchAnalysisGroupRemark = async () => {
        const response = await getAnalysisGroupRemark({ lab_test_id: labTestId, test_type: testType });
        setGroupRemarkData(response[0]);
    };
    const fetchCompltedTestList = async () => {
        const response = await getRequestedTestByStatus({ test_request_id: testRequestId, test_type: testType, status: 2 });
        setSelectList(response);
    };
    const fetchAnalysisResult = async () => {
        const response = await getGroupedTestResultByLabTestIdAndTestType({ lab_test_id: labTestId, test_type: testType });
        setResultData(response);
    };
    const fecthCalibrationValue = async (trqdID) => {
        const response = await getByTestTypeAndTestReq(trqdID);
        setCalibrationList(response);
    };
    useEffect(() => {
        dispatch(startLabTestDetailByTestRequestId(testRequestId, testType));
        fetchCompltedTestList();
        fetchAnalysisResult();
        fetchAnalysisGroupRemark();
    }, [testRequestId, labTestId, testType]);
    useEffect(() => {
        if (testType === '17' || testType === '14') {
            fecthCalibrationValue(resultData[0]?.test_detail_id);
        }
    }, [resultData]);
    return (
        <>
            <PDFViewer fileName="myPdf.pdf" style={{ width: '100%', height: '100vh' }}>
                <PdfGenGroup
                    groupRemarkData={groupRemarkData}
                    labTestDetails={labTestDetails}
                    labTestData={labTestData}
                    resultData={resultData}
                    calibrationVlaueList={calibrationVlaueList}
                    testType={testType}
                    selectValue={0}
                    selectList={selectList}
                />
            </PDFViewer>
        </>
    );
};

export default ViewCertificate;
