import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    base: 'labTest',
    byPaginationAndStatus: '/labTest/getByPagination',
    add: 'labTest/add',
    expiredList: 'labTest/disposal',
    archiveList: 'labTest/archive',
    updateArchiveStatus: 'labTest/update-archive-status',
    dynamicFilter: 'labTest/dynamic-filter',
    dynamicFilterDisposal: 'labTest/disposal-dynamic-filter',
    labManagementDashBoard: 'labTest/labDashboard',
    // Dashboards
    dasboard: 'labTest/dasboard',
    customerDashboard: 'customerPortal',
    labDataByTestReqID: 'customerPortal/findLabTestDataByTestRequestId'
};

// Axios call

// export const labTestsByStatus = async (data) => {
//     const res = await securedAxiosAPI.post(securedRoute?.base, data);
//     return res.data;
// };

export const labTestsByStatus = async () => {
    const { getState } = store;
    const { status, page, rowsPerPage } = getState().lab;

    const res = await securedAxiosAPI.post(securedRoute?.byPaginationAndStatus, { status, page, rowsPerPage });
    return res.data;
};
export const labTestsDetailByTestRequestId = async (id) => {
    const { getState } = store;
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(`${securedRoute?.base}/testRequestId/${id}`);
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.labDataByTestReqID, { test_request_id: id, customer_id: customerId });
    }
    return res.data[0];
};

export const labTestDetailsById = async (id) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    return res.data[0];
};
export const addUpdateLabTest = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.add}`, data);
    return res.data;
};
// Update status
export const labTestStatusUpdate = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/update-status`, data);
    return res.data[0];
};
// Result Entered or Finalized List
export const labTestResultEnteredResultFinalized = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/results-enterd-and-finalized`, { page, rowsPerPage });
    return res.data;
};
// Report  Sample Log
export const labTestReportSampleLog = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/report`, data);
    return res.data;
};
// Dashboard
export const dashboards = async () => {
    const { getState } = store;
    console.log(getState().user, 'Find User');
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(securedRoute?.dasboard);
    } else if (loggedInPortal === 'CUSTOMER') {
        //
        res = await securedAxiosAPI.get(`${securedRoute?.customerDashboard}/${customerId}`);
    }

    return res.data;
};
// expired List
export const expiredlabTests = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(securedRoute?.expiredList, { ...data, page, rowsPerPage });
    return res.data;
};
// archived List
export const archivedlabTests = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(securedRoute?.archiveList, { page, rowsPerPage });
    return res.data;
};
// update archive status
export const updateArchiveStatus = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateArchiveStatus, data);
    return res.data;
};

// Lab Filter
export const labTestFilter = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilter, data);
    return res.data;
};
// Disposal Filter
export const labTestDisposalFilter = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilterDisposal, data);
    return res.data;
};
// LAB DASHBOARD
export const labManagementDashBoard = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.labManagementDashBoard, data);
    return res.data;
};
