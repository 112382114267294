import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    base: 'nonConformance',
    getByTestReqId: 'nonConformance/testRequestId'
};

// Axios call

export const addNonConfermanceForm = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/add`, data);
    return res.data;
};

export const getNonConfermanceByTestReqId = async (testId) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.getByTestReqId}/${testId}`);
    return res.data[0];
};
