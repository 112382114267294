import { securedAxiosAPI, basicAxios } from 'utils/axios';
import { store } from '../../store';

const unsecuredRoute = {
    login: 'customer-user-auth/',
    refresh: 'customer-user-auth/refresh',
    logout: 'customer-user-auth/logout',
    forgodPasswordSendCode: 'auth/forgot-password',
    restPassword: 'auth/reset-password-by-code'
};

const securedRoute = {
    dashboard: 'customerPortal',
    // customerTestReqList: 'customerPortal/customerPortalGetByPagination',
    getByPaginationCustomerId: 'customerUser/users-by-customer-id-pagination'
};
export const customerLoginUser = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.login, data);
    return res.data;
};
export const customerRefresh = async (data) => {
    const res = await basicAxios.get(unsecuredRoute?.refresh, data);
    return res.data;
};

export const customerForgodPasswordSendCode = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.forgodPasswordSendCode, data);
    return res.data;
};
export const customerRestPassword = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.restPassword, data);
    return res.data;
};
/// securedRoute

export const customerDashboard = async (customerId) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.dashboard}/${customerId}`);
    return res.data;
};

// Test List
