import * as React from 'react';
import { Controller, useFormState } from 'react-hook-form';
import PropTypes from 'prop-types';

const FormField = React.forwardRef(
    ({ name, label, Component, rules, variant = 'outlined', chnageWatcher = () => {}, ...restProps }, ref) => {
        const formState = useFormState();

        return (
            <Controller
                name={name}
                ref={ref}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Component
                        onChange={(info) => {
                            onChange(info);
                            chnageWatcher(false);
                        }}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        error={Boolean(formState.errors && formState.errors[name])}
                        helperText={(formState.errors && formState.errors[name]?.message) || ' '}
                        label={label}
                        variant={variant}
                        {...restProps}
                    />
                )}
                rules={rules}
            />
        );
    }
);
FormField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    Component: PropTypes.func.isRequired,
    rules: PropTypes.object,
    variant: PropTypes.string,
    chnageWatcher: PropTypes.func
};
export default FormField;
