import { createSlice, createSelector } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'lookUpDetailsDataSlice',
    initialState: {
        page: 0,
        rowsPerPage: 10,
        isLoading: false,
        lookupDetailsData: [],
        lookupMasterData: [],
        lookupDetailForId1: [],
        lookupDetailForId2: [],
        lookupDetailForId3: [],
        lookupDetailForId4: [],
        lookupDetailForId5: []
    },
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        startLookUpDetails(state, action) {
            state.isLoading = true;
        },
        startLookUpMaster(state, action) {
            state.isLoading = true;
        },
        startLookUpDetailsByLookupId(state, action) {
            state.isLoading = true;
        },
        startLookUpDetailsByLookupId1(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails1Data(state, action) {
            state.lookupDetailForId1 = action.payload;
        },
        startLookUpDetailsByLookupId2(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails2Data(state, action) {
            state.lookupDetailForId2 = action.payload;
        },
        startLookUpDetailsByLookupId3(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails3Data(state, action) {
            state.lookupDetailForId3 = action.payload;
        },
        startLookUpDetailsByLookupId4(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails4Data(state, action) {
            state.lookupDetailForId4 = action.payload;
        },
        startLookUpDetailsByLookupId5(state, action) {
            state.isLoading = true;
        },
        setLookUpDetails5Data(state, action) {
            state.lookupDetailForId5 = action.payload;
        },
        setLookUpDetailsData(state, action) {
            state.lookupDetailsData = action.payload;
        },
        setLookUpMasterData(state, action) {
            state.lookupMasterData = action.payload;
        },
        startAddEditNewLookupsDetails(state, action) {
            state.isLoading = false;
        },

        startDelete(state, action) {
            state.isLoading = true;
        },
        startDeleteLookupDetails(state, action) {
            state.isLoading = true;
        },
        startUpdateingStatusById(state, action) {
            state.isLoading = true;
        },
        startUpdateData(state, action) {
            state.isLoading = true;
        },
        completedFetch(state, action) {
            state.isLoading = false;
        },
        // Roles Permission
        addUpdatePermissions(state, action) {
            state.isLoading = true;
        }
    }
});
///
export const {
    setRowsPerPage,
    setPage,
    startLookUpDetails,
    setLookUpDetailsData,
    startAddEditNewLookupsDetails,
    startDelete,
    startLookUpMaster,
    setLookUpMasterData,
    startLookUpDetailsByLookupId,
    startDeleteLookupDetails,
    startLookUpDetailsByLookupId1,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5,
    setLookUpDetails1Data,
    setLookUpDetails2Data,
    setLookUpDetails3Data,
    setLookUpDetails4Data,
    setLookUpDetails5Data,
    completedFetch,
    startUpdateingStatusById,
    startUpdateData,
    // Roles Permission
    addUpdatePermissions
} = slice.actions;

export default slice.reducer;

// Selectors
const mainLookupDetailsState = (state) => state.lookupDetails;
//
export const isLoadingSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.isLoading);
//
export const lookUpDetailsSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.lookupDetailsData);
//
export const lookUpDetailByIdSelector = createSelector([mainLookupDetailsState], (lookUpState) => ({
    lookupDetailForId1: lookUpState.lookupDetailForId1,
    lookupDetailForId2: lookUpState.lookupDetailForId2,
    lookupDetailForId3: lookUpState.lookupDetailForId3,
    lookupDetailForId4: lookUpState.lookupDetailForId4,
    lookupDetailForId5: lookUpState.lookupDetailForId5
}));
//
export const lookUpMasterSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.lookupMasterData);
//
// Page Selector
export const pageSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([mainLookupDetailsState], (lookUpState) => lookUpState.rowsPerPage);
