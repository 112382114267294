import { useEffect, useState } from 'react';
import { Grid, Typography, Box, Table, TableRow, TableCell, TableHead, TableBody, CardHeader, Button, useTheme } from '@mui/material';
import { readStringDate } from 'assets/function utils/functionUtils';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MultiListTableComp
} from 'views/pages/manage-test-requests/new-test-sample/previewForm';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    lookUpDetailByIdSelector,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5
} from 'store/slices/lookMaster/lookupDetailsSlice.selector';
import DTable from 'ui-component/Table/detailsTable';
import { gridSpacing } from 'store/constant';
import { GetLookUpDetailsDataByLookupId } from 'utils/apis/lookup';
import { useLocation, useNavigate } from 'react-router-dom';

const tdStyle = { padding: '5px' };

export const dataGeneratorValue = (array, valueArray, valueFeild = 'requested_test') => {
    const b = [];
    array.forEach((item) => {
        if (valueArray.indexOf(item[valueFeild]) >= 0) {
            b.push({ ...item, bool: true, name: item.look_up_name });
        }
    });

    return b;
};
const MultiListTableCompPrint = ({ data }) => {
    const naviagte = useNavigate();
    console.log(data, 'print data');
    return (
        <TableRow size="small">
            <TableCell size="small">{data?.name}</TableCell>
            <TableCell align="right" size="small">
                {data?.bool ? <DoneIcon size="small" color="success" /> : <CloseIcon size="small" color="error" />}
            </TableCell>
            <TableCell align="right" size="small">
                {data?.authorization_status > 0 ? <DoneIcon size="small" color="success" /> : <CloseIcon size="small" color="error" />}
            </TableCell>
            {/* <TableCell align="right" size="small">
                <Button
                    variant="contained"
                    disabled={data?.authorization_status !== 1}
                    onClick={() =>
                        naviagte(
                            `/sample-reception/test-requests/${data.test_request_id}/request_details/${data?.requested_test}/certificate`
                        )
                    }
                    endIcon={<TextSnippetIcon />}
                >
                    Certificate
                </Button>
            </TableCell> */}
        </TableRow>
    );
};
export default function TestDetailsComp({ testReqData, testRequestDetail }) {
    const [expanded, setExpanded] = useState('none');
    const [testDetails, setTestDetails] = useState({
        microbiology: [],
        allergens: [],
        nops: [],
        animal_feed: [],
        waterActivity: [],
        mycotoxin: [],
        moistureAnalysis: [],
        pH: []
    });
    const [waterActivity, setWaterActivity] = useState([]);
    const [mycotoxin, setMicotoxin] = useState([]);
    const [moistureAnalysis, setMoistureAnalysis] = useState([]);
    const [pH, setPH] = useState([]);

    // 14: 'WATER_ACTIVITY',
    // 15: 'MICOTOXIN_TYPE',
    // 16: 'MOISTURE_TYPE',
    // 17: 'PH_TYPE'

    const fetchWaterActivitys = async () => {
        const response = await GetLookUpDetailsDataByLookupId(14);
        setWaterActivity(response);
    };
    const fetchMicotoxin = async () => {
        const response = await GetLookUpDetailsDataByLookupId(15);
        setMicotoxin(response);
    };
    const fetchMoistureAnalysis = async () => {
        const response = await GetLookUpDetailsDataByLookupId(16);
        setMoistureAnalysis(response);
    };
    const fetchPH = async () => {
        const response = await GetLookUpDetailsDataByLookupId(17);
        setPH(response);
    };
    const theme = useTheme();
    const dispatch = useDispatch();
    const handleChangeAccordian = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const {
        lookupDetailForId2: animalFeedDB,
        lookupDetailForId3: microbiologyDB,
        lookupDetailForId4: allergensDB,
        lookupDetailForId5: nopsDB
    } = useSelector(lookUpDetailByIdSelector);
    // useEffect(() => {
    //     dispatch(startLookUpDetailsByLookupId2(3));
    //     dispatch(startLookUpDetailsByLookupId3(4));
    //     dispatch(startLookUpDetailsByLookupId4(5));
    //     dispatch(startLookUpDetailsByLookupId5(6));
    //     fetchPH();
    //     fetchMoistureAnalysis();
    //     fetchMicotoxin();
    //     fetchWaterActivitys();
    // }, []);
    useEffect(() => {
        const helperObject = {
            microbiology: [],
            allergens: [],
            nops: [],
            animal_feed: [],
            waterActivity: [],
            mycotoxin: [],
            moistureAnalysis: [],
            pH: []
        };
        testRequestDetail.forEach((data) => {
            switch (data.test_type) {
                case 3:
                    helperObject?.animal_feed.push(data.requested_test);
                    break;
                case 4:
                    helperObject?.microbiology.push(data.requested_test);
                    break;
                case 5:
                    helperObject?.allergens.push(data.requested_test);
                    break;
                case 6:
                    helperObject?.nops.push(data.requested_test);
                    break;
                case 14:
                    helperObject?.waterActivity.push(data.requested_test);
                    break;
                case 15:
                    helperObject?.mycotoxin.push(data.requested_test);
                    break;
                case 16:
                    helperObject?.moistureAnalysis.push(data.requested_test);
                    break;
                case 17:
                    helperObject?.pH.push(data.requested_test);
                    break;
                default:
            }
        });
        setTestDetails(helperObject);
    }, [testRequestDetail]);

    // console.log(testRequestDetail, 'testRequestDetail', testDetails.microbiology, microbiologyDB);
    // console.log(testReqData?.bbf_date);

    return (
        <Grid container columnSpacing={gridSpacing}>
            <Grid item xs={12}>
                <CardHeader subheader={<Typography variant="subtitle1">Test Request Details </Typography>} sx={{ paddingBottom: 0 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container sx={{ paddingLeft: '1rem' }}>
                    <Grid item xs={12}>
                        <DTable>
                            <tbody>
                                <tr style={{ padding: '10px' }}>
                                    <td style={tdStyle}> Sample Id : </td>
                                    <td style={tdStyle}>{testReqData?.sample_id} </td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Product Name : </td>
                                    <td style={tdStyle}>{testReqData?.p_name}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Product Code : </td>
                                    <td style={tdStyle}>{testReqData?.p_code}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>Batch Number : </td>
                                    <td style={tdStyle}>{testReqData?.batch}</td>
                                </tr>
                            </tbody>
                        </DTable>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container sx={{ paddingRight: '1rem' }}>
                    <Grid item xs={12}>
                        <DTable>
                            <tbody>
                                <tr style={{ padding: '10px' }}>
                                    <td style={tdStyle}>Manufacture Date : </td>
                                    <td style={tdStyle}>{readStringDate(testReqData?.mfg_date)} </td>
                                </tr>
                                {testReqData?.bbf_date && new Date(testReqData?.bbf_date).getFullYear() !== 1970 && (
                                    <tr>
                                        <td style={tdStyle}>Best Before Date : </td>
                                        <td style={tdStyle}>{readStringDate(testReqData?.bbf_date)}</td>
                                    </tr>
                                )}

                                <tr>
                                    <td style={tdStyle}>Sample Type : </td>
                                    <td style={tdStyle}>{testReqData?.sample_type_name}</td>
                                </tr>
                                <tr>
                                    <td style={tdStyle}>TAT Date : </td>
                                    <td style={tdStyle}>{readStringDate(testReqData?.turn_time)}</td>
                                </tr>
                            </tbody>
                        </DTable>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordian('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Animal Feed Intended</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableHead bgcolor={theme.palette.warning.light}>
                                <TableRow size="small">
                                    <TableCell size="small">Name :</TableCell>
                                    <TableCell align="right" size="small">
                                        Selected
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataGeneratorValue(testRequestDetail, testDetails.animal_feed).map((item) => (
                                    <MultiListTableComp theme={theme} data={item} />
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {testDetails.allergens.length > 0 && (
                <Grid item xs={12}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordian('panel2')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Allergens </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.allergens).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.microbiology.length > 0 && (
                <Grid item xs={12}>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordian('panel3')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Microbiology </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.microbiology).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.nops.length > 0 && (
                <Grid item xs={12}>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordian('panel4')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>NOPS Analysis </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.nops).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.waterActivity.length > 0 && (
                <Grid item xs={12}>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordian('panel5')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Water Activity </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.waterActivity).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.mycotoxin.length > 0 && (
                <Grid item xs={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChangeAccordian('panel6')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Mycotoxin </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.mycotoxin).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.moistureAnalysis.length > 0 && (
                <Grid item xs={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChangeAccordian('panel7')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Moisture Analysis </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.moistureAnalysis).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {testDetails.pH.length > 0 && (
                <Grid item xs={12} bgcolor={theme.palette.warning.light}>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChangeAccordian('panel8')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>pH </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead bgcolor={theme.palette.warning.light}>
                                    <TableRow size="small">
                                        <TableCell size="small">Name :</TableCell>
                                        <TableCell align="right" size="small">
                                            Selected
                                        </TableCell>
                                        <TableCell align="right" size="small">
                                            Authorised
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataGeneratorValue(testRequestDetail, testDetails.pH).map((item) => (
                                        <MultiListTableCompPrint theme={theme} data={item} />
                                    ))}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </Grid>
    );
}
