// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    page: 0,
    rowsPerPage: 10,
    status: 0,
    labTestsList: [],
    labTestDetails: [],
    labTestDetail: {},
    isLoading: false,
    message: '',
    validationData: {},
    archivedExpiredData: {},
    sampleReport: [],
    dashboadData: {},
    nonConformanceId: null
};

const slice = createSlice({
    name: 'lab',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination

        // Non Confermance Id For Edit
        setNonConformanceId(state, action) {
            state.nonConformanceId = action.payload;
        },
        //

        // Filter
        startLabTestDataByFilter(state, action) {
            state.isLoading = true;
        },
        // Lab Test Deatils Filter
        startLabTestDetailsDataByFilter(state, action) {
            state.isLoading = true;
        },
        // Status
        setListStatus(state, action) {
            state.status = action.payload;
        },
        // Status
        startLabTestsList(state, action) {
            state.isLoading = true;
        },
        // scheduledd list (Test Type)
        startLabTestsScheduledList(state, action) {
            state.isLoading = true;
        },
        startResultEnteredOrGeneratedLabTestList(state, action) {
            state.isLoading = true;
        },
        setLabTestsList(state, action) {
            state.labTestsList = action.payload;
        },
        startLabTestDetailByTestRequestId(state, action) {
            state.isLoading = true;
        },
        setLabTestDetail(state, action) {
            state.labTestDetail = action.payload;
        },
        startLabTestDetailsByLabTesttId(state, action) {
            state.isLoading = true;
        },
        setLabTestDetails(state, action) {
            state.labTestDetails = action.payload;
        },
        startLabTestStatusUpdate(state, action) {
            state.isLoading = true;
        },
        startAddUpdateLabTest(state, action) {
            state.isLoading = true;
        },
        // Report Sample Log
        startLabSampleLogReport(state, action) {
            state.isLoading = true;
        },
        setLabSampleLog(state, action) {
            state.sampleReport = action.payload;
        },
        /// Validation
        startValidationCheck(state, action) {
            state.isLoading = true;
        },
        startAllToBeValidatedList(state, action) {
            state.isLoading = true;
        },
        // Non-Conformance
        startAddNonConfermance(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
        },
        /// Lab Test Details Table
        startAddLabTestDetailsResult(state, action) {
            state.isLoading = true;
        },
        /// State Data
        setValidationData(state, action) {
            state.validationData = action.payload;
        },
        /// Dashboards View
        startDashboardsData(state, action) {
            state.isLoading = true;
        },
        setDashboardsData(state, action) {
            state.dashboadData = action.payload;
        },
        /// Archive And Disposal
        startArchiveAndDisposalAddData(state, action) {
            state.isLoading = true;
        },
        // Get Archive Data
        startArchivedDataByLabId(state, action) {
            state.isLoading = true;
        },
        /// archived Data Data
        setArchivedData(state, action) {
            state.archivedExpiredData = action.payload;
        },
        // Expired / Disposal List
        startArchivedExpiredLabTestSample(state, action) {
            state.isLoading = true;
        },
        //  Archive And Disposed Details by Id
        startArchivedExpiredSpecimensDetailsById(state, action) {
            state.isLoading = true;
        },
        // Result Entry in Batch
        startResultEntryInBatchGoupedByAnalysis(state, action) {
            state.isLoading = true;
        }
    }
});
// Actions Exports
export const {
    setNonConformanceId,
    setListStatus,
    setPage,
    setRowsPerPage,
    startLabTestDataByFilter,
    startLabTestDetailsDataByFilter,
    startAllToBeValidatedList,
    startLabTestsList,
    startLabTestsScheduledList,
    startResultEnteredOrGeneratedLabTestList,
    setLabTestsList,
    startLabTestDetailByTestRequestId,
    setLabTestDetail,
    startLabTestDetailsByLabTesttId,
    setLabTestDetails,
    startAddUpdateLabTest,
    startLabSampleLogReport,
    setLabSampleLog,
    startValidationCheck,
    startLabTestStatusUpdate,
    startAddNonConfermance,
    completedAction,
    startAddLabTestDetailsResult,
    setValidationData,
    startDashboardsData,
    setDashboardsData,
    startArchiveAndDisposalAddData,
    startArchivedDataByLabId,
    setArchivedData,
    startArchivedExpiredLabTestSample,
    startResultEntryInBatchGoupedByAnalysis
} = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// selectors

const labStates = (state) => state.lab;
/// Data Loading Selector
export const isLoadingSelector = createSelector([labStates], (labDataStates) => labDataStates.isLoading);

// lab_Tests_list
export const labTestsSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestsList);
// lab-test-data
export const labTestDetailsSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestDetail);
// for sample-splitting
export const validationDataSelector = createSelector([labStates], (labDataStates) => labDataStates.validationData);
// For Scheduled test result entry Lab Test Details
export const labTestDetailsByLabIdSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestDetails);
// Report Sample Log
export const labTestSampleReportSelector = createSelector([labStates], (labDataStates) => labDataStates.sampleReport);
// Dashboard Data
export const dashboardDataSelector = createSelector([labStates], (labDataStates) => labDataStates.dashboadData);
// Archived Data
export const archivedDataSelector = createSelector([labStates], (labDataStates) => labDataStates.archivedExpiredData);
// Page Selector
export const pageSelector = createSelector([labStates], (labDataStates) => labDataStates.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([labStates], (labDataStates) => labDataStates.rowsPerPage);
//
export const statusSelector = createSelector([labStates], (labDataStates) => labDataStates.status);
// Non Conformance Id
// nonConformanceId
export const nonConformanceIdSelector = createSelector([labStates], (labDataStates) => labDataStates.nonConformanceId);
