import * as React from 'react';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Step,
    Stepper,
    StepLabel,
    useTheme,
    Grid,
    CardContent,
    useMediaQuery,
    Typography,
    FormControl,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    TextField,
    Select,
    MenuItem,
    Divider
} from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import SampleInfo from './sampleInfo';
import Analysis from './analysisRequired';
import PreviewForm from './previewForm';
// import { startAllCustomer } from 'store/slices/customer/customer';
import {
    isLoadingSelector,
    lookUpDetailByIdSelector,
    startLookUpDetailsByLookupId1,
    startLookUpDetailsByLookupId2,
    startLookUpDetailsByLookupId3,
    startLookUpDetailsByLookupId4,
    startLookUpDetailsByLookupId5
} from 'store/slices/lookMaster/lookupDetailsSlice.selector';
import AnimatedLoading from 'ui-component/MuiReusableComponents/AnimationLoading/animationLoading';
import { startAddingNewTest, isLoadingSelector as isLoadingTestReqSelector } from 'store/slices/testRequest/testRequests';
import { arrayToObject, readStringDate, sendDateFormate, sendDateFormateReadStringToSendStrinf } from 'assets/function utils/functionUtils';
import { formNavigateSelector, setFormNavigate } from 'store/slices/formNavigate/formNavigateSlice';
// import { getProductsMappedWithCode } from 'utils/apis/productProductCode';
import { getAllCustomers } from 'utils/apis/customer';
import { Box } from '@mui/system';
import BackButton from 'ui-component/MuiReusableComponents/BackButton/back-button';
import { GetLookUpDetailsDataByLookupId } from 'utils/apis/lookup';
import FormHead from 'ui-component/MuiReusableComponents/FormHeadText/formHeadText';
import { getGenSampleIdByCustomerId, getGenSampleIdById } from 'utils/apis/generateSampleId';
import DTable from 'ui-component/Table/detailsTable';
import { loggedInPortalSelector, loggedInUserSelector } from 'store/slices/user';
import { customerDetailsSelector } from 'store/slices/customer/customer';
import { getCustomerTemplates } from 'utils/apis/testTemplates';
import { startTestTemplateDetails, testTemplateDetailsSelector } from 'store/slices/testTemplate/testTemplate.slice';

// Button
export function SumbitHandler({ value, handleChange, formStates }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingTestReqSelector);
    const formNavigate = useSelector(formNavigateSelector);
    const loggedInUser = useSelector(loggedInUserSelector);
    const loggedInPortal = useSelector(loggedInPortalSelector);
    React.useEffect(() => {
        if (formNavigate) {
            navigate('/sample-reception/test-requests');
            dispatch(setFormNavigate(false));
        }
    }, [formNavigate]);

    return (
        <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
                {value > 0 && (
                    <AnimateButton>
                        <Button variant="outlined" size="large" onClick={(e) => handleChange(e, value - 1)}>
                            Back
                        </Button>
                    </AnimateButton>
                )}
            </Grid>
            <Grid item>
                {value < 3 && (
                    <AnimateButton>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            size="large"
                            onClick={() => {
                                if (value === 2) {
                                    formStates.st_date = sendDateFormateReadStringToSendStrinf(formStates.st_date);
                                    formStates.mfg_date = sendDateFormateReadStringToSendStrinf(formStates.mfg_date);
                                    formStates.bbf_date = formStates.bbf_date
                                        ? sendDateFormateReadStringToSendStrinf(formStates.bbf_date)
                                        : null;
                                    formStates.turn_time = sendDateFormateReadStringToSendStrinf(formStates.turn_time);
                                    formStates.sent_date = sendDateFormateReadStringToSendStrinf(formStates.sent_date);

                                    const expiryData = new Date();
                                    expiryData.setMonth(expiryData.getMonth() + 6);

                                    // console.log(
                                    //     formStates.bbf_date ? sendDateFormateReadStringToSendStrinf(formStates.bbf_date) : null,
                                    //     'finde mw'
                                    // );

                                    dispatch(
                                        startAddingNewTest({
                                            ...formStates,
                                            expiry_date: sendDateFormate(expiryData),
                                            p_map_id: formStates.autocompleteProduct.id,
                                            generated_id: formStates.generated_id,
                                            generated_using: loggedInPortal,
                                            test_requested_by: loggedInUser?.userID
                                        })
                                    );
                                    //  alert(formStates?.generated_id);
                                }
                            }}
                        >
                            {value === 2 ? 'Submit' : 'Next'}
                        </Button>
                    </AnimateButton>
                )}
            </Grid>
        </Grid>
    );
}
// tabs
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};
SumbitHandler.propTypes = {
    value: PropTypes.number,
    handleChange: PropTypes.func,
    formStates: PropTypes.object
};
export default function TestSample() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isLoadingLookup = useSelector(isLoadingSelector);
    const [customer, setCustomers] = React.useState([]);
    const [waterActivity, setWaterActivity] = React.useState([]);
    const [mycotoxin, setMicotoxin] = React.useState([]);
    const [moistureAnalysis, setMoistureAnalysis] = React.useState([]);
    const [pH, setPH] = React.useState([]);
    // Record-Type
    const [selectRecordType, setRecordType] = React.useState('FRESH-SAMPLE');
    const [adHoc, setAdHoc] = React.useState(false);
    const [selectCustomerId, setSelectCustomerId] = React.useState('');
    const [genSampleId, setGeneratedSampleId] = React.useState([]);
    const [templates, setTemplates] = React.useState([]);
    const [selectGenSampleId, setSelectGenSampleId] = React.useState('');
    const [selectedTemplateId, setSelectedTemplateId] = React.useState('');
    const [genSampleDetails, setGenSampleDetail] = React.useState({});
    const loggedInPortal = useSelector(loggedInPortalSelector);
    const customerDetails = useSelector(customerDetailsSelector);
    const { templateData, templateDetails } = useSelector(testTemplateDetailsSelector);
    // 14: 'WATER_ACTIVITY',
    // 15: 'MICOTOXIN_TYPE',
    // 16: 'MOISTURE_TYPE',
    // 17: 'PH_TYPE'

    const fetchWaterActivitys = async () => {
        const response = await GetLookUpDetailsDataByLookupId(14);
        setWaterActivity(response);
    };
    const fetchMicotoxin = async () => {
        const response = await GetLookUpDetailsDataByLookupId(15);
        setMicotoxin(response);
    };
    const fetchMoistureAnalysis = async () => {
        const response = await GetLookUpDetailsDataByLookupId(16);
        setMoistureAnalysis(response);
    };
    const fetchPH = async () => {
        const response = await GetLookUpDetailsDataByLookupId(17);
        setPH(response);
    };
    const fetchCustomers = async () => {
        if (loggedInPortal === 'LAB') {
            const response = await getAllCustomers();
            setCustomers(response);
        }
    };
    const fetchGenSampelIdByCustomerId = async (customerId) => {
        const response = await getGenSampleIdByCustomerId(customerId);
        setGeneratedSampleId(response);
    };
    const fetchTemplatesByCustomerId = async (customerId) => {
        const response = await getCustomerTemplates(customerId);
        console.log(response, 'findme');
        setTemplates(response);
    };
    const fetchGenSampelIdById = async (Id) => {
        const response = await getGenSampleIdById(Id);
        setGenSampleDetail(response);
    };
    // step options
    const matchesXs = useMediaQuery(theme.breakpoints.down('lg'));
    const [value, setValue] = React.useState(0);
    // Mapped Product Name and Product Code
    const tdStyle = { padding: '5px' };
    React.useEffect(() => {
        // dispatch(startAllCustomer());
        dispatch(startLookUpDetailsByLookupId1(2));
        dispatch(startLookUpDetailsByLookupId2(3));
        dispatch(startLookUpDetailsByLookupId3(4));
        dispatch(startLookUpDetailsByLookupId4(5));
        dispatch(startLookUpDetailsByLookupId5(6));
        fetchCustomers();
        fetchPH();
        fetchMoistureAnalysis();
        fetchMicotoxin();
        fetchWaterActivitys();
    }, []);

    // console.log(customer, 'vcustomer');
    const {
        lookupDetailForId1: sampleTypeDB,
        lookupDetailForId2: animalFeedDB,
        lookupDetailForId3: microbiologyDB,
        lookupDetailForId4: allergensDB,
        lookupDetailForId5: nopsDB // NOPS
    } = useSelector(lookUpDetailByIdSelector);
    // const bbfDate = new Date();
    // bbfDate.setYear(bbfDate.getFullYear() + 1);
    const [formStates, setFormStates] = React.useState({
        p_name: '',
        p_code: '',
        batch: '',
        mfg_date: new Date(),
        st_date: new Date(),
        bbf_date: null, // bbfDate,
        animal_feed: [],
        sample_type: '',
        customer_id: '',
        microbiology: [],
        allergens: [],
        nops: [],
        waterActivity: [],
        mycotoxin: [],
        moistureAnalysis: [],
        pH: [],
        turn_time: new Date(),
        sent_date: new Date(),
        status: 0,
        result_status: 1,
        autocompleteProduct: {},
        generated_id: null,
        customer_ref: '',
        pallet_id: '',
        comment: ''
    });
    // const
    const handleChange = (event, newValue) => {
        //  alert(newValue);
        setValue(newValue);
    };
    const handleChangeRecordType = (event) => {
        setRecordType(event.target.value);
    };
    const steps = ['Sample Information', 'Analysis', 'Preview & Submit'];
    const handleChangeCustomer = (event) => {
        //  alert(event.target.value);
        setSelectCustomerId(event.target.value);
        setSelectGenSampleId('');
        setSelectedTemplateId('');
    };
    const handleChangeGenSampleId = (event) => {
        //  alert(event.target.value);
        setSelectGenSampleId(event.target.value);
    };
    const handleChangeTemplateId = (event) => {
        //  alert(event.target.value);
        setSelectedTemplateId(event.target.value);
    };
    const customersKeyValue = arrayToObject(customer, 'id', 'name');
    const genSamplesIdKeyValue = arrayToObject(genSampleId, 'id', 'sample_id');
    const genTemplatesIdKeyValue = arrayToObject(templates, 'id', 'name');
    React.useEffect(() => {
        if (selectCustomerId > 0) {
            fetchGenSampelIdByCustomerId(selectCustomerId);
            fetchTemplatesByCustomerId(selectCustomerId);
        }
    }, [selectCustomerId]);
    React.useEffect(() => {
        if (selectGenSampleId > 0) {
            fetchGenSampelIdById(selectGenSampleId);
        }
    }, [selectGenSampleId]);
    React.useEffect(() => {
        if (selectedTemplateId > 0) {
            dispatch(startTestTemplateDetails(selectedTemplateId));
        }
    }, [selectedTemplateId]);

    React.useEffect(() => {
        if (selectRecordType === 'ALREADY-SAMPLE' && genSampleDetails?.id > 0) {
            setFormStates((prev) =>
                genSampleDetails?.p_map_id > 0
                    ? {
                          ...prev,
                          customer_id: genSampleDetails?.customer_id,
                          sample_id: genSampleDetails?.sample_id,
                          p_code: genSampleDetails?.p_code,
                          p_name: genSampleDetails?.p_name,
                          generated_id: genSampleDetails?.id,
                          autocompleteProduct: { id: genSampleDetails?.p_map_id, p_name: genSampleDetails?.p_name }
                      }
                    : {
                          ...prev,
                          customer_id: genSampleDetails?.customer_id,
                          sample_id: genSampleDetails?.sample_id,
                          generated_id: genSampleDetails?.id
                      }
            );
        } else if (selectRecordType === 'ALREADY-TEMPLATE' && templateData?.id > 0) {
            const detailsTable = {
                animal_feed: [],
                microbiology: [],
                allergens: [],
                nops: [],
                waterActivity: [],
                mycotoxin: [],
                moistureAnalysis: [],
                pH: []
            };
            templateDetails.forEach((data) => {
                switch (data?.test_type) {
                    case 3:
                        detailsTable?.animal_feed.push(data?.requested_test);
                        break;
                    case 4:
                        detailsTable?.microbiology.push(data?.requested_test);
                        break;
                    case 5:
                        detailsTable?.allergens.push(data?.requested_test);
                        break;
                    case 6:
                        detailsTable?.nops.push(data?.requested_test);
                        break;
                    case 14:
                        detailsTable?.waterActivity.push(data.requested_test);
                        break;
                    case 15:
                        detailsTable?.mycotoxin.push(data.requested_test);
                        break;
                    case 16:
                        detailsTable?.moistureAnalysis.push(data.requested_test);
                        break;
                    case 17:
                        detailsTable?.pH.push(data.requested_test);
                        break;
                    default:
                }
            });
            setFormStates((prev) => ({
                ...prev,
                customer_id: templateData?.customer_id,
                sample_id: templateData?.sample_id,
                p_code: templateData?.p_code ? templateData?.p_code : '',
                p_name: templateData?.p_name ? templateData?.p_name : '',
                autocompleteProduct: {
                    id: templateData?.p_map_id ? templateData?.p_map_id : '',
                    p_name: templateData?.p_name ? templateData?.p_name : ''
                },
                animal_feed: detailsTable?.animal_feed,
                microbiology: detailsTable?.microbiology,
                allergens: detailsTable?.allergens,
                nops: detailsTable?.nops,
                waterActivity: detailsTable?.waterActivity,
                mycotoxin: detailsTable?.mycotoxin,
                moistureAnalysis: detailsTable?.moistureAnalysis,
                pH: detailsTable?.pH
            }));
        } else if (selectRecordType === 'FRESH-SAMPLE') {
            setFormStates({
                p_name: '',
                p_code: '',
                batch: '',
                mfg_date: new Date(),
                st_date: new Date(),
                bbf_date: null, // bbfDate,
                animal_feed: [],
                sample_type: '',
                customer_id: '',
                microbiology: [],
                allergens: [],
                nops: [],
                waterActivity: [],
                mycotoxin: [],
                moistureAnalysis: [],
                pH: [],
                turn_time: new Date(),
                sent_date: new Date(),
                status: 0,
                result_status: 1,
                autocompleteProduct: {},
                generated_id: null,
                customer_ref: '',
                pallet_id: '',
                comment: ''
            });
        }
    }, [selectRecordType, adHoc, genSampleDetails, templateData, templateDetails]);
    React.useEffect(() => {
        if (loggedInPortal === 'CUSTOMER') {
            setCustomers([{ id: customerDetails?.id, name: customerDetails?.name }]);
            setSelectCustomerId(customerDetails?.id);
        }
    }, [loggedInPortal, customerDetails]);
    console.log(formStates, 'New Test');
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard
                    title={
                        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                    {/* <BackButton /> */}
                                    <Typography variant="h3">Add Request </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                {selectRecordType === 'ALREADY-SAMPLE' && adHoc && (
                                    <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            {genSampleDetails?.sample_id}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    }
                    content={false}
                >
                    {adHoc ? (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} lg={2}>
                                <CardContent>
                                    <Stepper
                                        orientation={!matchesXs ? 'vertical' : 'horizontal'}
                                        activeStep={value}
                                        sx={{ pt: 3, pb: 5, textAlign: 'center' }}
                                    >
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </CardContent>
                            </Grid>
                            <Grid item xs={12} lg={10}>
                                <CardContent
                                    sx={{
                                        borderLeft: '1px solid',
                                        borderColor:
                                            theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[200],
                                        height: '100%'
                                    }}
                                >
                                    <TabPanel value={value} index={0}>
                                        {isLoadingLookup ? (
                                            <AnimatedLoading />
                                        ) : (
                                            <SampleInfo
                                                selectRecordType={selectRecordType}
                                                adHoc={adHoc}
                                                genSampleDetails={genSampleDetails}
                                                customer={customer}
                                                sampleType={sampleTypeDB}
                                                animalFeed={animalFeedDB}
                                                value={value}
                                                handleChange={handleChange}
                                                formStates={formStates}
                                                setFormStates={setFormStates}
                                                loggedInPortal={loggedInPortal}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {isLoadingLookup ? (
                                            <AnimatedLoading />
                                        ) : (
                                            <Analysis
                                                value={value}
                                                handleChange={handleChange}
                                                profileDetails={formStates}
                                                microbiology={microbiologyDB}
                                                nops={nopsDB}
                                                allergens={allergensDB}
                                                formStates={formStates}
                                                setFormStates={setFormStates}
                                                waterActivity={waterActivity}
                                                mycotoxin={mycotoxin}
                                                moistureAnalysis={moistureAnalysis}
                                                pH={pH}
                                            />
                                        )}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <PreviewForm
                                            customers={customer}
                                            value={value}
                                            setValue={setValue}
                                            handleChange={handleChange}
                                            profileDetails={formStates}
                                            microbiology={microbiologyDB}
                                            allergens={allergensDB}
                                            formStates={formStates}
                                            sampleType={sampleTypeDB}
                                            animalFeed={animalFeedDB}
                                            nops={nopsDB}
                                            waterActivity={waterActivity}
                                            mycotoxin={mycotoxin}
                                            moistureAnalysis={moistureAnalysis}
                                            pH={pH}
                                        />
                                    </TabPanel>
                                </CardContent>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={gridSpacing} sx={{ p: '0.5rem' }}>
                            <Grid item xs={12} textAlign="center">
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="group"
                                        name="radio-buttons-group"
                                        value={selectRecordType}
                                        onChange={handleChangeRecordType}
                                    >
                                        <FormControlLabel value="FRESH-SAMPLE" control={<Radio />} label="Create New Request" />
                                        <FormControlLabel
                                            value="ALREADY-SAMPLE"
                                            control={<Radio />}
                                            label="Create Request for an existing sample-Id"
                                        />
                                        <FormControlLabel
                                            value="ALREADY-TEMPLATE"
                                            control={<Radio />}
                                            label="Create Request from an template"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {selectRecordType === 'FRESH-SAMPLE' && (
                                <Grid item xs={12} textAlign="center">
                                    <Button variant="contained" onClick={() => setAdHoc(true)}>
                                        Continue
                                    </Button>
                                </Grid>
                            )}
                            {selectRecordType === 'ALREADY-SAMPLE' && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <FormHead headText="Select Customer / Company" />
                                            <Select
                                                sx={{ minWidth: '250px' }}
                                                fullWidth
                                                labelId="select"
                                                id="select-small"
                                                placeholder="Select "
                                                displayEmpty
                                                value={selectCustomerId}
                                                onChange={handleChangeCustomer}
                                                disabled={loggedInPortal === 'CUSTOMER'}
                                                renderValue={(selected) => {
                                                    if (selected?.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>Select Customer / Company</div>;
                                                    }
                                                    return customersKeyValue[selected];
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    Select Customer / Company
                                                </MenuItem>
                                                {customer.map((item) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Grid>
                                    {selectCustomerId > 0 && (
                                        <Grid item xs={6}>
                                            <Box>
                                                <FormHead headText="Select Sample-Id" />
                                                <Select
                                                    sx={{ minWidth: '250px' }}
                                                    labelId="select"
                                                    fullWidth
                                                    id="select-small"
                                                    placeholder="Select "
                                                    displayEmpty
                                                    value={selectGenSampleId}
                                                    onChange={handleChangeGenSampleId}
                                                    renderValue={(selected) => {
                                                        if (selected?.length === 0) {
                                                            return <div style={{ color: '#bfc4cc' }}>Select Available Sample Id</div>;
                                                        }
                                                        // genSamplesIdKeyValue
                                                        return genSamplesIdKeyValue[selected];
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        Select Available Sample Id
                                                    </MenuItem>
                                                    {genSampleId.map((item) => (
                                                        <MenuItem value={item.id}>{item.sample_id}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Grid>
                                    )}
                                    {selectGenSampleId > 0 && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontWeight="bold" textAlign="center">
                                                    {' '}
                                                    Generated Sample-id Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DTable>
                                                    <tbody>
                                                        <tr style={{ padding: '10px' }}>
                                                            <td style={tdStyle}> Sample Id </td>
                                                            <td style={tdStyle}>{genSampleDetails?.sample_id} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Product Name </td>
                                                            <td style={tdStyle}>
                                                                {genSampleDetails?.p_name
                                                                    ? genSampleDetails?.p_name
                                                                    : '- - - - - - - - - - - -'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Product Code </td>
                                                            <td style={tdStyle}>
                                                                {genSampleDetails?.p_code
                                                                    ? genSampleDetails?.p_code
                                                                    : '- - - - - - - - - - - -'}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={tdStyle}>Customer / Company </td>
                                                            <td style={tdStyle}>{genSampleDetails?.customer_name}</td>
                                                        </tr>
                                                    </tbody>
                                                </DTable>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DTable>
                                                    <tbody>
                                                        <tr style={{ padding: '10px' }}>
                                                            <td style={tdStyle}> Added By </td>
                                                            <td style={tdStyle}>
                                                                {genSampleDetails?.added_by_name
                                                                    ? genSampleDetails?.added_by_name
                                                                    : '- - - - - - - - - - - -'}
                                                            </td>
                                                        </tr>
                                                        <tr style={{ padding: '10px' }}>
                                                            <td style={tdStyle}> Added By Customer User </td>
                                                            <td style={tdStyle}>
                                                                {genSampleDetails?.customer_user_added_by_name
                                                                    ? genSampleDetails?.customer_user_added_by_name
                                                                    : '- - - - - - - - - - - -'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Added Date </td>
                                                            <td style={tdStyle}>{readStringDate(genSampleDetails?.added_date)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Generated Using </td>
                                                            <td style={tdStyle}>{genSampleDetails?.generated_using}</td>
                                                        </tr>
                                                    </tbody>
                                                </DTable>
                                            </Grid>
                                            <Grid item xs={12} textAlign="center">
                                                <Button variant="contained" onClick={() => setAdHoc(true)}>
                                                    Continue
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                            {selectRecordType === 'ALREADY-TEMPLATE' && (
                                <>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box>
                                            <FormHead headText="Select Customer / Company" />
                                            <Select
                                                sx={{ minWidth: '250px' }}
                                                fullWidth
                                                labelId="select"
                                                id="select-small"
                                                placeholder="Select "
                                                displayEmpty
                                                value={selectCustomerId}
                                                onChange={handleChangeCustomer}
                                                disabled={loggedInPortal === 'CUSTOMER'}
                                                renderValue={(selected) => {
                                                    if (selected?.length === 0) {
                                                        return <div style={{ color: '#bfc4cc' }}>Select Customer / Company</div>;
                                                    }
                                                    return customersKeyValue[selected];
                                                }}
                                            >
                                                <MenuItem disabled value="">
                                                    Select Customer / Company
                                                </MenuItem>
                                                {customer.map((item) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Grid>
                                    {selectCustomerId > 0 && (
                                        <Grid item xs={6}>
                                            <Box>
                                                <FormHead headText="Select Template" />
                                                <Select
                                                    sx={{ minWidth: '250px' }}
                                                    labelId="select"
                                                    fullWidth
                                                    id="select-small"
                                                    placeholder="Select "
                                                    displayEmpty
                                                    value={selectedTemplateId}
                                                    onChange={handleChangeTemplateId}
                                                    renderValue={(selected) => {
                                                        if (selected?.length === 0) {
                                                            return <div style={{ color: '#bfc4cc' }}>Select Available Template</div>;
                                                        }
                                                        // genSamplesIdKeyValue
                                                        return genTemplatesIdKeyValue[selected];
                                                    }}
                                                >
                                                    <MenuItem disabled value="">
                                                        Select Available Template
                                                    </MenuItem>
                                                    {templates.map((item) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Grid>
                                    )}
                                    {selectedTemplateId > 0 && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography fontWeight="bold" textAlign="center">
                                                    Template Details
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DTable>
                                                    <tbody>
                                                        <tr style={{ padding: '10px' }}>
                                                            <td style={tdStyle}>Template Name </td>
                                                            <td style={tdStyle}>{templateData?.name} </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Product Name </td>
                                                            <td style={tdStyle}>
                                                                {templateData?.p_name ? templateData?.p_name : '- - - - -  - '}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Product Code </td>
                                                            <td style={tdStyle}>
                                                                {templateData?.p_code ? templateData?.p_code : ' - - - - -  -'}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td style={tdStyle}>Customer / Company </td>
                                                            <td style={tdStyle}>{templateData?.customer_name}</td>
                                                        </tr>
                                                    </tbody>
                                                </DTable>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DTable>
                                                    <tbody>
                                                        <tr style={{ padding: '10px' }}>
                                                            <td style={tdStyle}> Added By </td>
                                                            <td style={tdStyle}>{templateData?.added_by_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={tdStyle}>Added Date </td>
                                                            <td style={tdStyle}>{readStringDate(templateData?.added_date)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td style={tdStyle}>Generated Using </td>
                                                            <td style={tdStyle}>{templateData?.added_using}</td>
                                                        </tr>
                                                    </tbody>
                                                </DTable>
                                            </Grid>
                                            <Grid item xs={12} textAlign="center">
                                                <Button variant="contained" onClick={() => setAdHoc(true)}>
                                                    Continue
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
}
