import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import { analysisNameTypes, analysisResults, calibrationHead, formsQMSType, resultPrefix } from 'constant';
import React, { useEffect, useState } from 'react';
import { readStringDate, capitalizeFirstLetter, getImageFormat } from 'assets/function utils/functionUtils';
import { getAllReportingLevel } from 'utils/apis/lookup';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        justifyContent: 'space-between'
    },
    imageContainer: {
        textAlign: 'center'
    },
    logo: {
        width: '150px',
        textAlign: 'center',
        margin: '0 auto'
    },
    header: {
        marginBottom: 10
    },
    content: {
        marginBottom: '15px',
        paddingBottom: '100px'
    },
    footer: {
        // position: 'absolute',
        // bottom: 20,
        // left: 20,
        // right: 20,
        textAlign: 'center',
        marginTop: 20
    },
    watermark: {
        position: 'absolute',
        opacity: 0.3, // Adjust opacity as needed
        fontSize: 40,
        color: '#d3d3d3', // Color of the watermark
        transform: 'rotate(-45deg)', // Rotate watermark if needed
        top: '80%', // Adjust positioning of watermark
        left: '-40%', // Adjust positioning of watermark
        right: '-50%'
    },
    watermark2: {
        position: 'absolute',
        opacity: 0.3, // Adjust opacity as needed
        fontSize: 40,
        color: '#d3d3d3', // Color of the watermark
        transform: 'rotate(-45deg)', // Rotate watermark if needed
        top: '10%', // Adjust positioning of watermark
        left: '-40%', // Adjust positioning of watermark
        right: '-50%'
    },
    watermark3: {
        position: 'absolute',
        opacity: 0.3, // Adjust opacity as needed
        fontSize: 40,
        color: '#d3d3d3', // Color of the watermark
        transform: 'rotate(-45deg)', // Rotate watermark if needed
        top: '150%', // Adjust positioning of watermark
        left: '-40%', // Adjust positioning of watermark
        right: '-50%'
    },
    watermark4: {
        position: 'absolute',
        opacity: 0.3, // Adjust opacity as needed
        fontSize: 40,
        color: '#d3d3d3', // Color of the watermark
        transform: 'rotate(-45deg)', // Rotate watermark if needed
        top: '220%', // Adjust positioning of watermark
        left: '-10%', // Adjust positioning of watermark
        right: '-50%'
    },
    watermark5: {
        position: 'absolute',
        opacity: 0.3, // Adjust opacity as needed
        fontSize: 40,
        color: '#d3d3d3', // Color of the watermark
        transform: 'rotate(-45deg)', // Rotate watermark if needed
        top: '40%', // Adjust positioning of watermark
        left: '-40%', // Adjust positioning of watermark
        right: '-50%'
    },
    row: {
        flexDirection: 'row'
        //  borderBottomWidth: 1,
        //  borderBottomColor: '#000',
        //   alignItems: 'center',
        //  height: 30
    },
    cell: {
        flex: 1,
        justifyContent: 'center',
        padding: '5px',
        border: '0.2px solid black'
    },
    textOffice: {
        fontSize: '10pt',
        fontWeight: '800'
    },
    textSilver: {
        color: 'rgb(75, 85, 101)',
        fontWeight: 'bold'
    },
    textMed: {
        fontSize: '8pt',
        fontWeight: '800',
        lineHeight: '1.5px'
    },
    tableMainWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: '8px'
    },
    twoFlexSections: {
        width: '49%'
    },
    cellHead: {
        justifyContent: 'center',
        padding: '5px',
        border: '0.2px solid black'
    }
});

const PdfGenGroup = ({
    groupRemarkData,
    labTestDetails,
    labTestData,
    resultData,
    calibrationVlaueList,
    testType,
    selectValue,
    selectList
}) => {
    const [reportingLevels, setReportingLevel] = React.useState('*Reporting levels (RL): ');
    const [resultEnteredImg, setResultEnteredImg] = React.useState(null);
    const [AuthImg, setAuthImg] = React.useState(null);
    const fetchReportingLevel = async (testLookupId) => {
        const resp = await getAllReportingLevel();
        let str = '';
        const listOfTestLookupIdNOPS = {};
        resultData.forEach((data) => {
            listOfTestLookupIdNOPS[data.test_lookup_id] = true;
        });
        resp?.forEach((item) => {
            if (listOfTestLookupIdNOPS[item?.lookup_details_id]) str += ` ${item?.value} ${capitalizeFirstLetter(item?.name)}. `;
        });
        setReportingLevel(` *Reporting levels (RL): ${str} `);
    };
    React.useEffect(() => {
        // only fetch when on NOPS
        if (selectList.length > 0 && resultData[0]?.test_type === 6) fetchReportingLevel(resultData[0]?.test_lookup_id);
    }, [resultData, selectList]);

    const reportinLevel = () => {
        let obj = {};
        if (selectValue === 0) {
            selectList.map((item) => {
                obj[item?.requested_test] = true;
                return '';
            });
        } else {
            obj = {};
            obj[selectValue] = true;
        }
        // let strg = '*Reporting levels (RL) : ';
        // reportingLevels?.forEach((data) => {
        //     if (obj[data?.lookup_details_id]) strg += ` ${data?.value} ${capitalizeFirstLetter(data?.name)} ,`;
        // });
        return reportingLevels;
    };
    useEffect(() => {
        if (selectValue === 0 && groupRemarkData?.authorised_by_signature?.data) {
            // authorised_by_signature
            const base64String = Buffer.from(groupRemarkData?.authorised_by_signature?.data, 'binary').toString('base64');
            setAuthImg(`data:${getImageFormat(groupRemarkData?.authorised_by_signature?.data)};base64,${base64String}`);
        } else {
            if (labTestDetails.authorised_by_signature) {
                const base64String = Buffer.from(labTestDetails?.authorised_by_signature?.data, 'binary').toString('base64');
                setAuthImg(`data:${getImageFormat(labTestDetails?.authorised_by_signature?.data)};base64,${base64String}`);
            } else {
                setAuthImg(null);
            }
            if (labTestDetails.result_entered_by_signature) {
                const base64String = Buffer.from(labTestDetails?.result_entered_by_signature?.data, 'binary').toString('base64');
                setResultEnteredImg(`data:${getImageFormat(labTestDetails?.result_entered_by_signature?.data)};base64,${base64String}`);
            } else {
                setResultEnteredImg(null);
            }
        }
    }, [groupRemarkData, labTestDetails, selectValue]);

    const midNum = Math.round(resultData?.length / 2);
    //  console.log(resultData, 'Find ME');
    return (
        <Document fileName="Wheelock">
            <Page style={styles.page} size="A4" fileName="Wheelock">
                <View>
                    <View style={styles.header} fixed>
                        <View style={styles.header}>
                            <View style={styles.imageContainer}>
                                <Image
                                    style={styles.logo}
                                    // src={LIMISLogo}
                                    source="/image/logo.jpg"
                                    alt="wheelocklab Image"
                                />
                            </View>

                            <Text style={{ ...styles.textOffice, textAlign: 'center', marginTop: '8px', ...styles.textSilver }}>
                                FOR-QMS-P27- {formsQMSType[testType]}
                                {analysisNameTypes[testType]} Certificate of Analysis
                            </Text>
                            {/* <Text style={styles.watermark}>Wheelock Wheelock Wheelock </Text>
                            <Text style={styles.watermark2}>Wheelock Wheelock Wheelock </Text>
                            <Text style={styles.watermark3}>Wheelock Wheelock Wheelock </Text> */}
                            <Text style={styles.watermark4}>Wheelock Wheelock Wheelock Wheelock </Text>
                            {/* <Text style={styles.watermark5}>Wheelock Wheelock Wheelock </Text> */}
                            <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px', marginBottom: '8px' }}>
                                Test Requested By
                            </Text>
                            <Text fontWeight={800} style={{ ...styles.textOffice, ...styles.textSilver }}>
                                {labTestData?.customer_name}
                            </Text>
                            <Text fontWeight={800} style={{ ...styles.textOffice, ...styles.textSilver }}>
                                {`${labTestData?.addressLine1}  ${labTestData?.addressLine2} ${labTestData?.postCode}, ${labTestData?.state}, ${labTestData?.country} `}
                            </Text>

                            <View style={{ width: '100%', borderBottomWidth: '0.2px', borderBottomColor: 'black' }} />
                            <Text fontWeight={800} style={{ ...styles.textOffice, textAlign: 'center', marginTop: '8px' }}>
                                Certificate No. : {groupRemarkData?.certificate_number}
                            </Text>
                        </View>
                    </View>

                    <View style={{}}>
                        <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px' }}>
                            Analysis Results
                        </Text>
                        {analysisResults[testType]?.map((strg) => (
                            <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px', color: 'rgb(75, 85, 101)' }}>
                                {strg}
                            </Text>
                        ))}
                    </View>
                    <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px' }}>
                        Sample Details
                    </Text>
                    <View style={styles.tableMainWrapper}>
                        <View style={styles.twoFlexSections}>
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Sample Id </Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{labTestData?.sample_id}</Text>
                            </View>
                            {/* Table Body */}
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Product Name </Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{labTestData?.p_name}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Product Code </Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{labTestData?.p_code}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Batch Number </Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{labTestData?.batch}</Text>
                            </View>
                        </View>
                        <View style={styles.twoFlexSections}>
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Manufacture Date </Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                    {readStringDate(labTestData?.mfg_date)}
                                </Text>
                            </View>
                            {/* Table Body */}
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Best Before Date</Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                    {readStringDate(labTestData?.bbf_date)}
                                </Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{ ...styles.cell, ...styles.textMed }}>Sample Type</Text>
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}> {labTestData?.sample_type}</Text>
                            </View>
                        </View>
                    </View>
                    {(testType === '17' || testType === '14') && (
                        <>
                            <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px', marginBottom: '8px' }}>
                                Calibration Results
                            </Text>
                            <View>
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cell, ...styles.textMed }}>{calibrationHead[resultData[0]?.test_type]} </Text>
                                    <Text style={{ ...styles.cell, ...styles.textMed }}>Actual Result</Text>
                                </View>
                                {calibrationVlaueList.map((data) => (
                                    <View style={styles.row}>
                                        <Text style={{ ...styles.cell, ...styles.textMed }}> {data.name} </Text>
                                        <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{data.value} </Text>
                                    </View>
                                ))}
                            </View>
                        </>
                    )}
                    <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px' }}>
                        Analysis Result
                    </Text>
                    <View style={styles.tableMainWrapper}>
                        <View style={styles.twoFlexSections}>
                            {resultData.slice(0, midNum).map((data, idx) => (
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cell, ...styles.textMed }}>{data?.test_name} : </Text>
                                    {data?.result_type === 'BOOLEAN_RESULT_TYPE' ? (
                                        <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                            {Math.trunc(data?.avg_result) === 1 ? 'Pass' : 'Fail'}
                                        </Text>
                                    ) : (
                                        <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                            {data?.result_prefix_status > 0 ? `${resultPrefix[data?.result_prefix_value]}` : ``}{' '}
                                            {Number(data?.avg_result).toFixed(3)} <Text style={{ fontWeight: 'bold' }}>&#177;</Text>
                                            {data?.trd_range} {data?.unit_name}
                                        </Text>
                                    )}
                                </View>
                            ))}
                        </View>
                        <View style={styles.twoFlexSections}>
                            {resultData.slice(midNum, resultData?.length).map((data, idx) => (
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cell, ...styles.textMed }}>{data?.test_name} : </Text>
                                    {data?.result_type === 'BOOLEAN_RESULT_TYPE' ? (
                                        <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                            {Math.trunc(data?.avg_result) === 1 ? 'Pass' : 'Fail'}
                                        </Text>
                                    ) : (
                                        <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                            {data?.result_prefix_status > 0 ? `${resultPrefix[data?.result_prefix_value]}` : ``}{' '}
                                            {Number(data?.avg_result).toFixed(3)} <span style={{ fontWeight: 'bold' }}>&#177;</span>
                                            {data?.trd_range} {data?.unit_name}
                                        </Text>
                                    )}
                                </View>
                            ))}
                        </View>
                    </View>

                    {selectValue !== 0 && (
                        <>
                            <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px', marginBottom: '8px' }}>
                                Analysis Impression
                            </Text>
                            <View>
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cellHead, ...styles.textMed, width: '15%' }}>Remark : </Text>
                                    <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                        {labTestDetails?.remark}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cellHead, ...styles.textMed, width: '15%' }}>Uncertainty : </Text>
                                    <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                        {labTestDetails?.uncertainty}
                                    </Text>
                                </View>
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cellHead, ...styles.textMed, width: '15%' }}>Control Info. : </Text>
                                    <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                        {labTestDetails?.ctrl_info}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                {resultData[0]?.test_type === 6 && (
                                    <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{reportinLevel()}</Text>
                                )}
                            </View>

                            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '8px' }}>
                                {labTestDetails?.authorised_by > 0 && (
                                    <View>
                                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                                            <Text style={{ ...styles.textOffice }}>Authorised by : </Text>
                                            <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                                &nbsp; {labTestDetails?.authorised_by_name}
                                            </Text>
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                                            <Text style={{ ...styles.textOffice }}>Authorised Date :</Text>
                                            <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                                &nbsp; {readStringDate(labTestDetails?.finalized_date)}
                                            </Text>
                                        </View>
                                    </View>
                                )}
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: '8px'
                                        }}
                                    >
                                        <Text style={{ ...styles.textOffice }}>Result Entered by : </Text>
                                        <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                            &nbsp; {labTestDetails?.result_entered_by_name}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: '8px'
                                        }}
                                    >
                                        <Text style={{ ...styles.textOffice }}>Result Entered Date : </Text>
                                        <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                            &nbsp; {readStringDate(labTestDetails?.result_entered_date)}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </>
                    )}
                    {selectValue === 0 && (
                        <View>
                            {resultData[0]?.test_type === 6 && (
                                <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>{reportinLevel()}</Text>
                            )}
                            <View style={{ marginTop: '15px' }}>
                                <Text fontWeight={800} style={{ ...styles.textOffice, marginTop: '8px', marginBottom: '8px' }}>
                                    Analysis Impression
                                </Text>
                            </View>

                            <View>
                                <View style={styles.row}>
                                    <Text style={{ ...styles.cellHead, ...styles.textMed, width: '15%' }}>Remark : </Text>
                                    <Text style={{ ...styles.cell, ...styles.textMed, ...styles.textSilver }}>
                                        {groupRemarkData?.remark}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '8px' }}>
                                {groupRemarkData?.status === 2 && (
                                    <View>
                                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                                            <Text style={{ ...styles.textOffice }}>Authorised by : </Text>
                                            <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                                &nbsp; {groupRemarkData?.remark_auth_name}
                                            </Text>
                                        </View>
                                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                                            <Text style={{ ...styles.textOffice }}>Authorised Date :</Text>
                                            <Text style={{ ...styles.textOffice, ...styles.textSilver }}>
                                                &nbsp; {readStringDate(groupRemarkData?.remark_added_date)}
                                            </Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    )}

                    <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: '8px' }}>
                        <View>
                            {AuthImg ? (
                                <Image style={{ ...styles.logo, height: '65px', width: '150px' }} src={AuthImg} alt="Auth Signature" />
                            ) : (
                                <View style={{ width: '200px', height: '65px', borderBottom: '1px solid black' }} />
                            )}

                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '8px',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Text style={{ ...styles.textOffice }}>
                                    {selectValue === 0 ? groupRemarkData?.remark_auth_name : labTestDetails?.authorised_by_name}
                                </Text>
                            </View>
                        </View>
                        {selectValue !== 0 && (
                            <View>
                                {resultEnteredImg ? (
                                    <Image
                                        style={{ ...styles.logo, height: '65px', width: '150px' }}
                                        src={resultEnteredImg}
                                        alt="HRS Signature"
                                    />
                                ) : (
                                    <View style={{ width: '200px', height: '65px', borderBottom: '1px solid black' }} />
                                )}

                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: '8px',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.textOffice, textAlign: 'center' }}>
                                        {labTestDetails?.result_entered_by_name}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>

                <View
                    fixed
                    style={{
                        ...styles.footer,
                        ...styles.textOffice,
                        borderTopWidth: '0.5px',
                        borderTopColor: 'black',
                        paddingBottom: '16px'
                    }}
                >
                    <Text style={{ marginTop: '8px' }}>
                        Wheel Scientific Services Ltd, Aston Mill, Wrenbury Road, Aston, Nantwich, England CW5 8DH
                    </Text>
                    <Text style={{ marginTop: '8px' }}>
                        Company Registration Number : <Text style={{ ...styles.textSilver }}> 12942980</Text>
                    </Text>
                    <Text
                        style={{ textAlign: 'right', fontSize: '9px', color: 'rgb(75, 85, 101)' }}
                        render={({ pageNumber, totalPages }) => ` Page ${pageNumber} / ${totalPages}`}
                    />
                </View>
            </Page>
        </Document>
    );
};
export default PdfGenGroup;
