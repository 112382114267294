import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    base: 'calibrationValue',
    getByTestReqDetailId: 'calibrationValue/getByTestTypeAndTestReq',
    updateCalValue: 'calibrationValue/update-value'
};

// Axios call

export const addNonConfermanceForm = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.base}/add`, data);
    return res.data;
};

export const getByTestTypeAndTestReq = async (testReqId, testType) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.getByTestReqDetailId}/${testReqId}/${testType}`);
    return res.data;
};
export const updateCalValue = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute.updateCalValue, data);
    return res.data;
};
// calibrationValue/update-value
