import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    baseUrl: 'generateSampleId',
    getByPagination: '/generateSampleId',
    delete: '/generateSampleId',
    addEdit: '/generateSampleId/add',
    dynamicFilter: '/generateSampleId/dynamicFilter',
    getByCustomerId: '/generateSampleId/customer_id',
    // Customer Portal
    customerPortalGetByPaginationGenId: '/customerPortal/customerPortalGetByPaginationGenId'
};
//
export const getByPagination = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().generatedSampleId;
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;

    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.baseUrl, { page, rowsPerPage });
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.customerPortalGetByPaginationGenId, { page, rowsPerPage, customer_id: customerId });
    }

    return res?.data;
};
//
export const generatedSampleIDFilter = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilter, data);
    return res.data;
};

//
export const addEditGenSampleId = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addEdit, data);
    return res.data;
};
// get by customer-id
export const getGenSampleIdByCustomerId = async (id) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.getByCustomerId}/${id}`);
    return res.data;
};
// get by id
export const getGenSampleIdById = async (id) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.baseUrl}/${id}`);
    return res.data[0];
};
// delete
export const deleteAdHocGenSampleId = async (id) => {
    const res = await securedAxiosAPI.delete(`${securedRoute?.delete}/${id}`);
    return res.data;
};
