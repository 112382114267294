import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    labDetailsByLabTestId: '/labTestDetail/labTests',
    resultEntry: '/labTestDetail/result',
    afterValidationLabTestList: '/labTestDetail/get-all-by-pagination-status',
    afterValidationLabTestListGroupBYAnalysisType: '/labTestDetail/get-all-by-pagination-status-group-by-analysis',
    testing: '/labTestDetail/labTestId/17',
    dynamicFilter: '/labTestDetail/dynamic-filter',
    getSingleTestResultResultByLabTestIdAndTestRequested: '/labTestDetail/getAverageResultByLabTestIdAndTestRequested',
    getAnalysisGroupedTestByLabTestIdandTestType: '/labTestDetail/getAverageByLabTestIdandTestType',
    scheduledTestByAnalysisType: 'labTestDetail/findScheduledTestByAnalysisType',
    // grouped by Analysis type
    resultEntryBatch: '/labTestDetail/result-entery-batch',
    customerGetAverageByLabTestIdandTestType: 'customerPortal/customerGetAverageByLabTestIdandTestType'
};

// Axios call

export const labTestByLabTestIdAndReqTest = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.labDetailsByLabTestId, data);
    return res.data;
};
export const addlabDetailsResultEnter = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.resultEntry}`, data);
    return res.data;
};

export const afterValidationLabListByStatus = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(`${securedRoute?.afterValidationLabTestList}`, { ...data, page, rowsPerPage });
    return res.data;
};
export const afterValidationLabListByStatusGroupByAnalysis = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(`${securedRoute?.afterValidationLabTestListGroupBYAnalysisType}`, {
        ...data,
        page,
        rowsPerPage
    });
    return res.data;
};
// FIlter API For Scheduled Test And Result Generations
export const dynamicFilter = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().lab;
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilter, { ...data, page, rowsPerPage });
    return res.data;
};
/// Single Test Result by Lab test Id and Requested Test Id
export const getSingleTestResultByLabTestIdAndReqTestId = async (data) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.getSingleTestResultResultByLabTestIdAndTestRequested, data);
    } else if (loggedInPortal === 'CUSTOMER') {
        //
        res = await securedAxiosAPI.post(securedRoute?.customerGetAverageByLabTestIdandTestType, { ...data, customer_id: customerId });
    }

    return res.data;
};
//  Analysis Grouped Test Result Lab Test Id and Test Type
export const getGroupedTestResultByLabTestIdAndTestType = async (data) => {
    const res = await securedAxiosAPI.post(`${securedRoute?.getAnalysisGroupedTestByLabTestIdandTestType}`, data);
    return res.data;
};
// scheduled Test By Analysis Type
export const scheduledTestByAnalysisType = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.scheduledTestByAnalysisType, data);
    return res.data;
};
//
export const enterResultEntryBatch = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.resultEntryBatch, data);
    return res.data;
};
