import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    completedAction,
    startDeleteGeneratedSampleId,
    startGeneratedSampleId,
    setGeneratedSampleId,
    startAddingNewGenSampleId,
    startDynamicFilter
} from './generatedSampleId';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';
import { getByPagination, generatedSampleIDFilter, deleteAdHocGenSampleId, addEditGenSampleId } from 'utils/apis/generateSampleId';
import { openSnackbar } from '../snackbar';
// Saga handlers

// Add  New GenSampleId  Response (1)
function* addingResponseHandler({ payload }) {
    try {
        yield call(addEditGenSampleId, payload);
        yield put(startGeneratedSampleId());
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Sample ID Generated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Add New GenSampleId Action watcher(1)
function* startAddingGenSampleIdFetchWatcher() {
    yield takeLatest(startAddingNewGenSampleId.type, addingResponseHandler);
}

// GenSampleId Response (2)
function* genSampleIdResponseHandler() {
    try {
        const response = yield call(getByPagination);
        yield put(setGeneratedSampleId(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  GenSampleId Watcher (2)
function* startGenSampleIdFetchWatcher() {
    yield takeLatest(startGeneratedSampleId.type, genSampleIdResponseHandler);
}
// Delete GenSampleId Response (3)
function* deleteGenSampleIdResponseHandler({ payload }) {
    const { filterData, filterStatus, page, rowsPerPage, id } = payload;
    try {
        yield call(deleteAdHocGenSampleId, id);
        if (filterStatus) {
            yield put(startDynamicFilter({ ...filterData, page, rowsPerPage }));
        } else {
            yield put(startGeneratedSampleId());
        }
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Deleted Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Delete GenSampleId  Watcher (3)
function* startDeleteGenSampleIdWatcher() {
    yield takeLatest(startDeleteGeneratedSampleId.type, deleteGenSampleIdResponseHandler);
}
//  Dynamic Filter  Response (4)
function* startDynamicFilterResponseHandler({ payload }) {
    try {
        const response = yield call(generatedSampleIDFilter, payload);
        yield put(setGeneratedSampleId(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Dynamic Filter watcher(4)
function* startstartDynamicFilterWatcher() {
    yield takeLatest(startDynamicFilter.type, startDynamicFilterResponseHandler);
}

// Accumulator
export default function* generatedSampleId() {
    yield all([
        call(startAddingGenSampleIdFetchWatcher),
        call(startGenSampleIdFetchWatcher),
        call(startstartDynamicFilterWatcher),
        call(startDeleteGenSampleIdWatcher)
    ]);
}
