import { takeLatest, all, call, put } from 'redux-saga/effects';
// actions
import {
    startAddAndUpdateTestTemplate,
    startListTemplate,
    setTestRequestTemplates,
    startTestTemplateDetails,
    setTestTemplateDetails,
    completedAction,
    startUpdateStatus
} from './testTemplate.slice';
import { openSnackbar } from '../snackbar';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';
// apis
import {
    getTestTemplateByPagination,
    addNewOrUpdateTestTemplate,
    getTestTemplateData,
    updateTestTemplateStatus
} from 'utils/apis/testTemplates';

// Test Template List Response (1)
function* testTemplateListResponseHandler({ payload }) {
    try {
        const response = yield call(getTestTemplateByPagination, payload);
        yield put(setTestRequestTemplates(response));
        yield put(completedAction());
    } catch (error) {
        console.log(error?.response?.status, 'finding Error');
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Template List Watcher (1)
function* startTestTemplateListFetchWatcher() {
    yield takeLatest(startListTemplate.type, testTemplateListResponseHandler);
}

// Test Template Details Response (2)
function* testTemplateDetailsResponseHandler({ payload }) {
    try {
        const response = yield call(getTestTemplateData, payload);
        yield put(setTestTemplateDetails(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Template Details Watcher (2)
function* startTestTemplateDetailsFetchWatcher() {
    yield takeLatest(startTestTemplateDetails.type, testTemplateDetailsResponseHandler);
}

// Test Template Add / update Response (3)
function* testTemplateAddOrUpdateResponseHandler({ payload }) {
    try {
        yield call(addNewOrUpdateTestTemplate, payload);
        yield put(
            openSnackbar({
                open: true,
                message: 'Success',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(setFormNavigate(true));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Template Add / update  Watcher (3)
function* startTestTemplateAddOrUpdateFetchWatcher() {
    yield takeLatest(startAddAndUpdateTestTemplate.type, testTemplateAddOrUpdateResponseHandler);
}
// Test Template  update status Response (4)
function* testTemplateUpdateStatusResponseHandler({ payload }) {
    try {
        yield call(updateTestTemplateStatus, payload);
        yield put(
            openSnackbar({
                open: true,
                message: 'Success',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: true
            })
        );
        yield put(startListTemplate());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Template  update status  Watcher (4)
function* startTestTemplateUpdateStatusFetchWatcher() {
    yield takeLatest(startUpdateStatus.type, testTemplateUpdateStatusResponseHandler);
}

// Accumulator
export default function* testTemplateDataSaga() {
    yield all([
        call(startTestTemplateListFetchWatcher),
        call(startTestTemplateDetailsFetchWatcher),
        call(startTestTemplateAddOrUpdateFetchWatcher),
        call(startTestTemplateUpdateStatusFetchWatcher)
    ]);
}
