import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    base: 'archiveDisposal',
    addUpdate: 'archiveDisposal/add',
    getByLabId: '/archiveDisposal/lab_id'
};
export const archiveDisposalAdd = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addUpdate, data);
    return res.data;
};
// get details by lab id
export const archiveDisposalByLabId = async (labId) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.getByLabId}/${labId}`);
    return res.data[0];
};
// get details by  id
export const archiveDisposalById = async (id) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    return res.data[0];
};
