import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme, zIndex = 1300 }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        zIndex: 100
    },
    zIndex
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon color="error" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};
// Component Main

export default function PopupDialogs({ title, children, open, setOpen, size, needTitleBar = true, fullWidth, zIndex }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <BootstrapDialog
                fullWidth={fullWidth}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth={size || 'md'}
                zIndex={zIndex}
            >
                {needTitleBar && (
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <Typography variant="subtitle1" fontWeight="bold" component="div" sx={{ fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                    </BootstrapDialogTitle>
                )}

                <DialogContent dividers>{children}</DialogContent>
            </BootstrapDialog>
        </>
    );
}
PopupDialogs.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    size: PropTypes.string,
    needTitleBar: PropTypes.bool
};
