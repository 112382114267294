import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

import accountReducer from 'store/accountReducer';

import { useLocation } from 'react-router-dom';

// constant
const initialState = {
    isInitialized: false
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [pathName, setPathName] = useState('/');
    const pathname = useLocation().pathname;

    useEffect(() => {
        setPathName(pathname);
    }, []);

    return <JWTContext.Provider value={{ ...state, pathName, setPathName }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
