import { Box, Typography, Divider, Button } from '@mui/material';
import MyCollapsePaper from './collapseFilterCoverBox.style';

import SearchIcon from '@mui/icons-material/Search';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const CollapseFilterCoverBox = ({ children }) => (
    <MyCollapsePaper>
        <Box className="box">
            <Typography className="text" variant="subtitle1" gutterBottom>
                Filter
            </Typography>
            <Divider />
        </Box>

        {children}
    </MyCollapsePaper>
);
export default CollapseFilterCoverBox;

export const SearchBox = ({ onClickReset, setCollapseFilterControl }) => (
    <Box className="parent-search-box">
        <Button size="small" className="button-space" variant="contained" type="submit" startIcon={<SearchIcon />}>
            Search
        </Button>
        <Button className="button-space" onClick={onClickReset} size="small" color="error" variant="contained">
            Reset
        </Button>
        <Button
            variant="outlined"
            size="small"
            onClick={() => setCollapseFilterControl(false)}
            startIcon={<CancelPresentationIcon color="error" />}
        >
            Close
        </Button>
    </Box>
);
