import { securedAxiosAPI } from 'utils/axios';

// APIS

// Secured Routes

const securedRoute = {
    base: '/products/',
    addUpdate: 'archiveDisposal/add',
    getByLabId: '/archiveDisposal/lab_id'
};
export const getProductsMappedWithCode = async () => {
    const res = await securedAxiosAPI.get(securedRoute?.base);
    return res.data;
};
