import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';
// APIS

// Secured Routes

const securedRoute = {
    addUpdate: 'testTemplate/add',
    base: 'testTemplate',
    byPagination: 'testTemplate/byPagination',
    testTemplateDetailsById: '/testTemplate/getTemplateDetails/##ID##/##CUSTOMER_ID##',
    byPaginationCustomerId: 'testTemplate/byPagination',
    updateStatus: 'testTemplate/update-status',
    customerTemplates: 'testTemplate/customer-template/##CUSTOMER_ID##'
};

export const getTestTemplateByPagination = async (data) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().testTemplate;
    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage, ...data });
    } else if (loggedInPortal === 'CUSTOMER') {
        res = await securedAxiosAPI.post(securedRoute?.byPagination, { page, rowsPerPage, customer_id: customerId });
    }
    return res.data;
};

export const addNewOrUpdateTestTemplate = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addUpdate, data);
    return res.data;
};

export const getTestTemplateData = async (id) => {
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    const { customerId } = getState().user.loggedInUser;
    let res;
    if (loggedInPortal === 'LAB') {
        res = await securedAxiosAPI.get(
            `${securedRoute?.testTemplateDetailsById}`.replaceAll('##ID##', id).replaceAll('##CUSTOMER_ID##', 0)
        );
    } else if (loggedInPortal === 'CUSTOMER') {
        // customer portal replaceAll
        res = await securedAxiosAPI.get(
            `${securedRoute?.testTemplateDetailsById}`.replaceAll('##ID##', id).replaceAll('##CUSTOMER_ID##', customerId)
        );
    }

    return res.data;
};

export const updateTestTemplateStatus = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updateStatus, data);
    return res.data;
};
export const getCustomerTemplates = async (customerId) => {
    const res = await securedAxiosAPI.get(securedRoute?.customerTemplates.replaceAll('##CUSTOMER_ID##', customerId));
    return res.data;
};
