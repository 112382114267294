import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, Link, Button, TableRow } from '@mui/material';

// assets

import TestRequestMenu from 'ui-component/MuiReusableComponents/Menus/TestRequestMenu';
import {
    testRequestsSelector,
    pageSelector,
    rowsPerPageSelector,
    setRowsPerPage,
    setPage,
    startTestRequestData,
    startTestRequestDetailsByTestRequestId
} from 'store/slices/testRequest/testRequests';
import { useDispatch, useSelector } from 'react-redux';
import PopupDialogs from 'ui-component/MuiReusableComponents/MuiPopupDialog/new-popup';
import ConformationPopup from 'ui-component/MuiReusableComponents/DeletePopup/deletePopup';
import { startAddUpdateLabTest } from 'store/slices/lab/lab';
import { readStringDate, sendDateFormate } from 'assets/function utils/functionUtils';

// ==============================|| Test Request In-transit||============================== //

const InTransitTestList = ({ setOpen, filterStatus, filterData }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const page = useSelector(pageSelector);
    const rowsPerPage = useSelector(rowsPerPageSelector);
    const testData = useSelector(testRequestsSelector);

    const [selectedRow, setSelectedRow] = useState({});
    const [openConfimation, setOpenConfimation] = useState(false);
    const handleChangePage = (event, newPage) => {
        dispatch(setPage(newPage));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPage(event.target.value));
        dispatch(setPage(0));
    };

    const markAsRecieved = (row) => {
        setSelectedRow(row);
        setOpenConfimation(true);
        // set the selected row
    };

    const closePopup = () => setOpenConfimation(false);
    const OnYesAutharize = () => {
        // alert('ook');
        // onSubmitResult();
        //     const { sample_id, test_request_id } = selectedRow;
        const expiryData = new Date();
        expiryData.setMonth(expiryData.getMonth() + 6);
        dispatch(
            startAddUpdateLabTest({
                data: {
                    sample_id: selectedRow.sample_id,
                    test_request_id: selectedRow.id,
                    expiry_date: sendDateFormate(expiryData),
                    remark: '',
                    status: 0
                },
                isItnew: true,
                filterStatus,
                filterData
            })
        );
        //  dispatch(startDeleteUser({ filterData, filterStatus, page, rowsPerPage, id: row.id }));
        closePopup();
    };
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>#</TableCell>
                            <TableCell>Sample Id</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Product Code</TableCell>
                            <TableCell>Batch No.</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Sample Type</TableCell>
                            <TableCell>Test Types</TableCell>
                            <TableCell sx={{ pr: 3 }}>TAT Date</TableCell>
                            <TableCell>Request Created Date</TableCell>
                            <TableCell>Customer Reference</TableCell>
                            <TableCell align="center">Status</TableCell>

                            <TableCell align="center" sx={{ pr: 3 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testData[0] &&
                            testData[0].map((row, index) => (
                                <TableRow sx={{ bgcolor: index % 2 === 0 ? theme?.palette?.grey[50] : '' }} key={index}>
                                    <TableCell size="small" sx={{ pl: 3 }}>
                                        {page * rowsPerPage + index + 1}
                                    </TableCell>
                                    <TableCell size="small">
                                        <Link
                                            onClick={() => {
                                                dispatch(startTestRequestData(row?.id));
                                                dispatch(startTestRequestDetailsByTestRequestId(row?.id));
                                                setOpen(true);
                                            }}
                                            sx={{ cursor: 'pointer' }}
                                            title="sample details"
                                        >
                                            {row.sample_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell size="small">{row.p_name}</TableCell>
                                    <TableCell size="small">{row.p_code}</TableCell>
                                    <TableCell size="small">{row.batch}</TableCell>
                                    <TableCell size="small">{row.customer_name}</TableCell>
                                    <TableCell size="small">{row.sample_type_name}</TableCell>
                                    <TableCell size="small">{row?.test_type_names?.replaceAll('_TYPE', '')}</TableCell>
                                    <TableCell size="small" sx={{ whiteSpace: 'nowrap' }}>
                                        {readStringDate(row.turn_time)}
                                    </TableCell>
                                    <TableCell size="small" sx={{ whiteSpace: 'nowrap' }}>
                                        {readStringDate(row.added_date)}
                                    </TableCell>
                                    <TableCell size="small">{row.customer_ref === null ? '- - - - - - - -' : row.customer_ref}</TableCell>
                                    <TableCell align="center" size="small">
                                        {Number(row.status) === 0 && (
                                            <Chip
                                                label="Not Received at lab"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.orange.light + 80,
                                                    color: theme.palette.orange.dark
                                                }}
                                            />
                                        )}
                                        {Number(row.status) === 1 && (
                                            <Chip
                                                label="Inprocess"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.warning.light + 60,
                                                    color: theme.palette.warning.dark
                                                }}
                                            />
                                        )}
                                        {Number(row.status) === 2 && (
                                            <Chip
                                                label="Rejected"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.orange.light + 80,
                                                    color: theme.palette.orange.dark
                                                }}
                                            />
                                        )}
                                        {Number(row.status) === 4 && (
                                            <Chip
                                                label="Partially Completed"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.warning.light + 60,
                                                    color: theme.palette.warning.dark
                                                }}
                                            />
                                        )}
                                        {Number(row.status) === 5 && (
                                            <Chip
                                                label="Completed"
                                                size="small"
                                                sx={{
                                                    background:
                                                        theme.palette.mode === 'dark'
                                                            ? theme.palette.dark.main
                                                            : theme.palette.success.light + 60,
                                                    color: theme.palette.success.dark
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell size="small" align="center" sx={{ pr: 3 }}>
                                        <Button onClick={() => markAsRecieved(row)} variant="contained" size="small">
                                            Mark as Received
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={testData[1] && testData[1][0].total_count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <PopupDialogs title="" needTitleBar={false} size="lg" open={openConfimation} setOpen={setOpenConfimation}>
                <ConformationPopup
                    text={`Do you want to accept new request  " ${selectedRow?.sample_id} " ?`}
                    onClickNo={closePopup}
                    onClickYes={OnYesAutharize}
                />
            </PopupDialogs>
        </>
    );
};

export default InTransitTestList;
