// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// project imports

// ----------------------------------------------------------------------

const initialState = {
    usersList: [],
    page: 0,
    rowsPerPage: 10,
    loggedInPortal: null,
    loggedInUser: {
        userID: '',
        email: '',
        name: '',
        userToken: '',
        roleId: null,
        roleName: '',
        customerId: null,
        userRoles: [{ sub_screen_key_name: 'DASHBOARD' }]
    },
    userDetails: {},
    isLoading: false,
    message: '',
    lastLoginPage: {
        portal: '/login',
        customerID: null
    }
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        startUserDataUpdate(state, action) {
            state.isLoading = true;
        },
        // Pagination
        startAllUsers(state, action) {
            state.isLoading = true;
        },
        // Dynamic Filter
        startDynamicFilter(state, action) {
            state.isLoading = true;
        },
        // delete
        startDeleteUser(state, action) {
            state.isLoading = true;
        },
        // Reset Current Password
        startResetCurentPassword(state, action) {
            state.isLoading = true;
        },
        // Admin reset user password
        startResetUserPasswordByAdmin(state, action) {
            state.isLoading = true;
        },
        startUserDetailById(state, action) {
            state.isLoading = true;
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload;
        },
        setAllUsers(state, action) {
            state.usersList = action.payload;
        },
        startAddingNewUser(state, action) {
            state.isLoading = true;
        },
        addNewUser(state, action) {
            state.usersList = [...state.usersList, { ...action.payload }];
        },
        startLoginUser(state, action) {
            state.isLoading = true;
        },
        loggedInUser(state, action) {
            state.loggedInUser = action.payload;
        },
        logOutUser(state, action) {
            return {
                usersList: [],
                page: 0,
                rowsPerPage: 10,
                loggedInPortal: null,
                loggedInUser: {
                    userID: '',
                    email: '',
                    name: '',
                    userToken: '',
                    roleId: null,
                    roleName: '',
                    customerId: null,
                    userRoles: [{ sub_screen_key_name: 'DASHBOARD' }]
                },
                userDetails: {},
                isLoading: false,
                message: '',
                lastLoginPage: {
                    portal: '/login',
                    customerID: null
                }
            };
        },
        setloggedInPortal(state, action) {
            state.loggedInPortal = action.payload;
        },
        startRefresh(state, action) {
            state.isLoading = true;
        },
        startLogout(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
        },
        // update paswd by code
        startUpdatePaswdByCode(state, action) {
            state.isLoading = true;
        },
        setLastLoginPagePortale(state, action) {
            state.lastLoginPage = action.payload;
        }
    }
});
// Actions Exports
export const {
    startResetCurentPassword,
    startResetUserPasswordByAdmin,
    setPage,
    setRowsPerPage,
    setAllUsers,
    startAllUsers,
    startAddingNewUser,
    startLoginUser,
    setloggedInPortal,
    loggedInUser,
    logOutUser,
    completedAction,
    startRefresh,
    startLogout,
    startDynamicFilter,
    startUserDetailById,
    setUserDetails,
    startUserDataUpdate,
    startDeleteUser,
    startUpdatePaswdByCode,
    setLastLoginPagePortale
} = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// selectors

const userStates = (state) => state.user;
/// Data Loading Selector
export const isLoadingSelector = createSelector([userStates], (userDataState) => userDataState.isLoading);
// LoggedIn-user Selector
export const loggedInUserSelector = createSelector([userStates], (userDataState) => userDataState.loggedInUser);
// Page Selector
export const pageSelector = createSelector([userStates], (userDataState) => userDataState.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([userStates], (userDataState) => userDataState.rowsPerPage);
// Users List Selector
export const usersListSelector = createSelector([userStates], (userDataState) => userDataState.usersList);
// Users Details Selector
export const usersDetailSelector = createSelector([userStates], (userDataState) => userDataState.userDetails);
// portal-login loggedInPortal
export const loggedInPortalSelector = createSelector([userStates], (userDataState) => userDataState.loggedInPortal);

export const lastLoginPageSelector = createSelector([userStates], (userDataState) => userDataState.lastLoginPage);
